import { useEffect } from "react";

const Logout = (props) => {
  useEffect(() => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("user");
    localStorage.removeItem("company");

    props.history.replace("/login");
  }, []);

  return null;
};

export default Logout;
