import React, { useEffect, useState } from "react";
import ImageUpload from "../components/common/ImageUpload";
import { Button, Form, Input, Spin, Table } from "antd";
import loadingIcon from "../components/common/loadingIcon";
import { reduxFunctions } from "../helper/ReduxHelper";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import TripItem from "../components/TripItem";
import Empty from "../assets/svgs/empty.svg";
import currency from "currency.js";
import moment from "moment";
import { pastTripColumns } from "../configs/tableConfig";
import { TRIP_STATE } from "../constants/Enums";
import { authenticatedCompany } from "../helper/AuthHelper";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";

const DriverDetails = (props) => {
  const [data, setData] = useState({});
  const [loadingImage, setLoadingImage] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [uploadUrl, setUploadUrl] = useState(null);
  const [s3ImageUrl, sets3ImageUrl] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [company] = useState(authenticatedCompany());
  // eslint-disable-next-line
  const [trips, setTrips] = useState([]);
  const [tripsData, setTripsData] = useState([]);
  const [currentTrip, setCurrentTrip] = useState({});

  const handleChange = ({ currentTarget: input }) => {
    let newData = { ...data };
    newData[input.name] = input.value;
    setData(newData);
  };

  const query = useParams();

  const handleRemoveDriver = async () => {
    const payload = { driver_ids: [data?._id] };

    await props
      .removeDriver(payload, company._id)
      .then(async (res) => {
        toast.success("Driver removed successfully");
        props.history.push("/app/driver-list");
      })
      .catch((error) => {
        toast.error(error.error?.response?.data?.message);
        return error;
      });
  };

  const removeDriver = () => {
    confirmAlert({
      title: "Confirm",
      message: "Are you sure you want to remove this driver?. This action cannot be reversed.",
      buttons: [{ label: "Yes", onClick: () => handleRemoveDriver() }, { label: "No" }],
    });
  };

  useEffect(() => {
    props
      .getUserWithId(query.id)
      .then((res) => {
        setData(res.payload.data.user);
      })
      .catch((error) => {
        return error;
      });
  }, []);

  useEffect(() => {
    if (data._id && company._id) {
      props
        .getCompanyDriverTrips(company._id, data._id)
        .then((res) => {
          setTrips(res.payload.data.trips);
          const curTrip = res.payload.data.trips.find(
            (trip) =>
              trip.state.value === TRIP_STATE.ACCEPTED ||
              trip.state.value === TRIP_STATE.TRIP_STARTED ||
              trip.state.value === TRIP_STATE.ARRIVED ||
              trip.state.value === TRIP_STATE.ON_ROUTE ||
              trip.state.value === TRIP_STATE.AT_DESTINATION ||
              trip.state.value === TRIP_STATE.OFFLOADING_ENDED ||
              trip.state.value === TRIP_STATE.OFFLOADING_STARTED ||
              trip.state.value === TRIP_STATE.ON_ROUTE_DROPOFF ||
              trip.state.value === TRIP_STATE.AT_DROPOFF
          );
          setCurrentTrip(curTrip);

          let newTrips = [];
          if (res.payload.data.trips) {
            res.payload.data.trips.forEach((trip) => {
              const newTrip = {
                id: `${trip._id}`,
                details: `From ${trip.start_address} to ${trip.end_address}`,
                amount: currency(trip.cost_id.price).format({ symbol: "₦" }),
                date: moment(`${trip.start_time}`).format("DD/MM/YYYY"),
                start_time: trip.start_time,
                status: trip.state.value === "FINISHED" ? "Paid" : "Pending",
                state: trip.state.value,
              };
              newTrips.push(newTrip);
            });
            setTripsData([...newTrips]);
          }
        })
        .catch((error) => {
          return error;
        });
    }
  }, [data, company]);

  const EmptyIcon = <img src={Empty} alt="Empty list" style={{ width: "100px" }} />;

  return (
    <div>
      <h2 className="header">Driver</h2>
      <div className="driver-details">
        <div className="image-upload">
          <ImageUpload
            data={data}
            loadingImage={loadingImage}
            uploadUrl={uploadUrl}
            fileList={fileList}
            imageUrl={imageUrl}
            setUploadUrl={setUploadUrl}
            sets3ImageUrl={sets3ImageUrl}
            setImageUrl={setImageUrl}
            setFileList={setFileList}
            setLoadingImage={setLoadingImage}
            s3ImageUrl={s3ImageUrl}
            listType="picture-card"
            button={false}
          />
        </div>
        <Form
          layout="vertical"
          name="login-form"
          // onFinish={handleSave}
          className="profile-form-container"
        >
          <Form.Item label="Full Name" className="label">
            <Input
              placeholder=""
              disabled={true}
              className="input"
              name="full_name"
              value={data.full_name}
              onChange={handleChange}
              size={"large"}
            />
          </Form.Item>
          <Form.Item label="Phone Number" className="label">
            <Input
              placeholder=""
              disabled={true}
              className="input"
              name="phone_number"
              value={data.phone_number}
              onChange={handleChange}
              size={"large"}
            />
          </Form.Item>
          <Form.Item label="Email Address" className="label">
            <Input
              placeholder=""
              disabled={true}
              className="input"
              name="email"
              value={data.email}
              onChange={handleChange}
              size={"large"}
            />
          </Form.Item>
          <Form.Item label="Home Address" className="label">
            <Input
              placeholder=""
              disabled={true}
              className="input"
              name="home_address"
              value={data.home_address}
              onChange={handleChange}
              size={"large"}
            />
          </Form.Item>

          {/*<Form.Item label="Vehicle Assigned" className='label'>*/}
          {/*    <Input placeholder="" disabled={true} className='input' name='vehicle_assigned' value={data.vehicle_assigned} onChange={handleChange} size={"large"}/>*/}
          {/*</Form.Item>*/}

          <Form.Item>
            <Button htmlType="submit" block className="all-button driver-remove" size={"large"} onClick={removeDriver}>
              {props.loadingRemoveDriver ? <Spin indicator={loadingIcon} /> : <span>Remove Driver</span>}
            </Button>
          </Form.Item>
        </Form>
      </div>

      <div className="driver-current-trip">
        <h2 className="header">Current Trip</h2>
        {currentTrip?._id ? (
          <TripItem data={currentTrip} key={currentTrip._id} />
        ) : (
          <div style={{ height: "25vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
            {EmptyIcon}
          </div>
        )}
      </div>
      <div className="driver-past-trips">
        <h2 className="header">Past Trips</h2>
        <Table
          className="table"
          locale={{ emptyText: EmptyIcon }}
          columns={pastTripColumns}
          dataSource={tripsData}
          size="large"
          pagination={false}
        />
      </div>
    </div>
  );
};
const mapStateToProps = ({ userById, loadingRemoveDriver }) => ({ userById, loadingRemoveDriver });

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(DriverDetails);
