import React from "react";
import RegisterCompanyForm from "../../components/RegisterCompanyForm";
import AuthLayout from "../../components/layout-components/AuthLayout";

const RegisterCompanyScreen = (props) => {
  const handleRedirect = () => {
    props.history.push("/verify");
  };

  return (
    <AuthLayout>
      <RegisterCompanyForm handleRedirect={handleRedirect} />
    </AuthLayout>
  );
};

export default RegisterCompanyScreen;
