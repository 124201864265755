import React, { useState, useEffect } from "react";
import "../../../styles/main.css";
import { Row, Col, DatePicker } from "antd";
import useWindowSize from "../../../hooks/useWindowSize";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
import currency from "currency.js";
const { RangePicker } = DatePicker;

Highcharts.setOptions({
  global: {
    useUTC: false,
  },
});

const LineComponent = ({ data, head, unit, dateData, amount, growth, info, growthInfo, title, status }) => {
  const toolTipFormat = (value) => {
    switch (title) {
      case "Distance":
        return `${value} KM`;
      case "trips":
        return `${value} trips`;
      case "revenue":
        return currency(value).format({ symbol: unit });
      default:
        return value;
    }
  };

  const colors = ["#E27626"];
  const [chartOptions, setChartOptions] = useState({
    chart: {
      type: "spline",
    },
    title: {
      text: "",
    },
    plotOptions: {},
    colors: colors,
    yAxis: {
      labels: {
        formatter: function () {
          return unit ? currency(this.value).format({ symbol: unit }) : this.value;
        },
      },
    },
    tooltip: {
      pointFormatter: function () {
        let value = toolTipFormat(this.y);
        return (
          '<span style="color:' + this.series.color + '">' + this.series.name + "</span>: <b>" + value + "</b><br />"
        );
      },
      xDateFormat: "%Y-%m-%d",
      shared: true,
    },
    xAxis: {
      title: {
        text: "Date",
      },
      labels: {
        formatter: function () {
          return Highcharts.dateFormat("%d/%m/%y", this.value);
        },
        rotation: 45,
      },

      crosshair: true,

      type: "datetime",
    },
    series: [
      {
        name: head,
        marker: {
          enabled: false,
        },
        data: data,
      },
    ],
  });

  useEffect(() => {
    if (data.length > 0) {
      setChartOptions({
        series: [
          {
            data: data,
          },
        ],
      });
    }
  }, [data]);

  const setChartRange = (date) => {
    if (date) {
      setChartOptions({
        xAxis: {
          min: moment(date[0]._d).valueOf(),
          max: moment(date[1]._d).valueOf(),
        },
      });
    }
  };
  const size = useWindowSize();

  return (
    <Row className="total-revenue revenue-margin">
      <Col span={size.width > 800 ? 6 : 24} className="c1">
        <h2 className="header">{head}</h2>
        <p className="date">{dateData}</p>
        <div className="total-revenue-content">
          <h2 className="header">{amount}</h2>
          <p className={`p1 ${status ? "loss" : "growth"} `}>
            {growth} {growthInfo}
          </p>
          <p className="p2">{info}</p>
        </div>
      </Col>
      <Col span={size.width > 800 ? 18 : 24}>
        <div className="report-container">
          <div className="chart-filter-container">
            <RangePicker className="chart-range-picker" onChange={setChartRange} size="large" />
          </div>
        </div>
        {/*<CustomLine config={config} height= '400px'/>*/}
        <HighchartsReact
          highcharts={Highcharts}
          options={chartOptions}
          updateArgs={[true]}
          oneToOne={true}
          allowChartUpdate={true}
        />
      </Col>
    </Row>
  );
};

export default LineComponent;
