export const USER = {
  USER: 0,
  DRIVER: 1,
};

export const CONTAINERS = {
  TEN: "10",
  TWENTY: "20",
  FORTY: "40",
};

export const TRIP_STATE = {
  IDLE: "IDLE",
  PENDING: "PENDING",
  ACCEPTED: "ACCEPTED",
  ON_ROUTE: "ON_ROUTE",
  ARRIVED: "ARRIVED",
  FLAGGED: "FLAGGED",
  IMPOUNDED: "IMPOUNDED",
  TRIP_STARTED: "TRIP_STARTED",
  AT_DESTINATION: "AT_DESTINATION",
  OFFLOADING_STARTED: "OFFLOADING_STARTED",
  OFFLOADING_ENDED: "OFFLOADING_ENDED",
  ON_ROUTE_DROPOFF: "ON_ROUTE_DROPOFF",
  AT_DROPOFF: "AT_DROPOFF",
  FINISHED: "FINISHED",
  CANCELLED: "CANCELLED",
};

export const STATUS = {
  AVAILABLE: 0,
  ON_TRIP: 1,
  REQUESTING_NOW: 2,
  PENDING_PAYMENT: 3,
  NO_ACTIVE_CARD: 4,
  IDLE: 5,
};

export const PAYOUT_STATES = {
  PAID: "PAID",
  REVERSED: "REVERSED",
  FAILED: "FAILED",
  IN_PROGRESS: "IN_PROGRESS",
};

export const USER_STATUSES = {
  PENDING: "pending",
  VERIFIED: "verified",
  BLOCKED: "blocked",
};

export const TRACKER_STATUSES = {
  INVALID: "invalid",
  VALID: "valid",
  PENDING: "pending",
  FAILED: "failed",
  OFFLINE: "offline", //Tracker state when last update from tracker > 1 week
  ONLINE: "online", //Tracker state when last update from tracker <= 1 day
  IDLE: "idle", // Tracker state when 1day > last update from tracker <= 1week
  FAULTY: "faulty",
  NA: "n/a", // Tracker is unavailable
};

export const VEHICLE_TYPES = {
  "20FT Flat Bed": 20,
  "40FT Flat Bed": 40,
  "20FT Containerized Truck": 1,
  "40FT Containerized Truck": 2,
  "20FT Closed Body Truck": 3,
  "24FT Closed Body Truck": 4,
  "32FT Closed Body Truck": 5,
  "Low Loader": 6,
  "Open Body Truck": 7,
  "Reefer Truck": 8,
  "Half Sided Body Truck": 9,
  "Full Sided Body Truck": 10,
  "Arewa Truck": 11,
  "Covered Body Truck": 12,
  "Tipper Truck": 13,
  "Lowbed Truck": 14,
};

export const truckOptions = [
  { label: "20FT Flat Bed", value: VEHICLE_TYPES["20FT Flat Bed"], container: true },
  { label: "40FT Flat Bed", value: VEHICLE_TYPES["40FT Flat Bed"], container: true },
  { label: "Half Sided Body Truck", value: VEHICLE_TYPES["Half Sided Body Truck"], container: true },
  { label: "20FT Containerized Truck", value: VEHICLE_TYPES["20FT Containerized Truck"] },
  { label: "40FT Containerized Truck", value: VEHICLE_TYPES["40FT Containerized Truck"] },
  { label: "20FT Closed Body Truck", value: VEHICLE_TYPES["20FT Closed Body Truck"] },
  { label: "24FT Closed Body Truck", value: VEHICLE_TYPES["24FT Closed Body Truck"] },
  { label: "32FT Closed Body Truck", value: VEHICLE_TYPES["32FT Closed Body Truck"] },
  { label: "Full Sided Body Truck", value: VEHICLE_TYPES["Full Sided Body Truck"] },
  { label: "Covered Body Truck", value: VEHICLE_TYPES["Covered Body Truck"] },
  { label: "Low Loader", value: VEHICLE_TYPES["Low Loader"] },
  { label: "Open Body Truck", value: VEHICLE_TYPES["Open Body Truck"] },
  { label: "Reefer Truck", value: VEHICLE_TYPES["Reefer Truck"] },
  { label: "Arewa Truck", value: VEHICLE_TYPES["Arewa Truck"] },
  { label: "Tipper Truck", value: VEHICLE_TYPES["Tipper Truck"] },
  { label: "Lowbed Truck", value: VEHICLE_TYPES["Lowbed Truck"] },
];

export const CARGO_CATEGORIES = {
  CONSTRUCTION_MATERIALS: "CONSTRUCTION MATERIALS",
  AUTOMOBILES: "AUTOMOBILES",
  FOOD_AND_AGRICULTURE: "FOOD AND AGRICULTURE",
  DANGEROUS_GOODS: "DANGEROUS GOODS",
  ELECTRONICS: "ELECTRONICS",
  PHARMACEUTICALS: "PHARMACEUTICALS",
  LIVESTOCK: "LIVESTOCK",
};

export const DOCUMENT_TYPES = {
  CAC: "CAC CERTIFICATE",
  INTL_PASSPORT: "INTL_PASSPORT",
  STAFF_ID: "STAFF_ID",
  vNIN: "vNIN",
  DRIVERS_LICENSE: "DRIVERS_LICENSE",
};

export const VERIFICATION_STATUSES = {
  VERIFIED: "VERIFIED",
  IN_REVIEW: "IN REVIEW",
};

export const CARGO_TYPES = {
  CONTAINER: "CONTAINER",
  LOOSE_GOODS: "LOOSE_GOODS",
};

export const MAX_WEIGHT = 100;

export const MAX_TONNAGE = (type) => {
  const max = type === CARGO_TYPES.CONTAINER ? 45 : 100;
  return max;
};
