import React, { useEffect, useState } from "react";
import { Table, Divider } from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { reduxFunctions } from "../../helper/ReduxHelper";
import "../../styles/main.css";
import moment from "moment";
import currency from "currency.js";
import LoadingState from "../../components/util-components/LoadingState";

const Payout = (props) => {
  const [data, setData] = useState(
    props.payouts.map((payout) => {
      const newPayout = {
        id: payout._id,
        amount: currency(payout.amount).format({ symbol: "₦" }),
        date: moment(payout.end_date).format("DD MMMM YYYY"),
      };
      return newPayout;
    })
  );

  const getData = async () => {
    await props
      .getPayouts()
      .then(async (res) => {
        const newData = res.payload.data.payouts.map((payout) => {
          const newPayout = {
            id: payout._id,
            amount: currency(payout.amount).format({ symbol: "₦" }),
            date: moment(payout.end_date).format("DD MMMM YYYY"),
          };
          return newPayout;
        });
        setData(newData);
      })
      .catch((error) => {
        // toast.error('Error getting payout data!');
        return error;
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const columns = [
    {
      title: "Payout Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Total",
      dataIndex: "amount",
      key: "amount",
    },
  ];

  const nextDate = data.slice(-1)[0]
    ? moment(moment(data.slice(-1)[0].date).add(1, "week")._d).format("DD MMMM")
    : "pending";

  return (
    <div className="dashboard-payout">
      {props.loadingPayouts ? (
        <div
          style={{
            height: "410px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <LoadingState />
        </div>
      ) : (
        <>
          <h2>Payouts</h2>
          <Table columns={columns} dataSource={data} bordered={false} pagination={false} size={"small"} />
          <p style={{ textAlign: "center" }}>You next payout is estimated for {nextDate}</p>
          <Divider />
          <div className="d1">
            <Link to="/app/finance/payouts">View all payouts</Link>
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = ({ user, payouts, loadingPayouts }) => ({ user, payouts, loadingPayouts });

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(Payout);
