import React, { useEffect } from "react";
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps";

import { trip as newTrip } from "../../helper/UtilityHelper";

let trip = { ...newTrip };
let vehicle_location = {
  longitude: 0,
  latitude: 0,
};

export const Map = () => {
  //   const [vehicleLocation, setVehicleLocation] = useState({});
  //   const [region, setRegion] = useState(null);
  //   const [bearing, setBearing] = useState(null);

  return trip.start_location && trip.end_location ? (
    <GoogleMap
      defaultZoom={10}
      defaultCenter={{ lat: trip.start_location.coordinates[1], lng: trip.start_location.coordinates[0] }}
    >
      <Marker
        key={"1"}
        position={{
          lat: trip.start_location.coordinates[1],
          lng: trip.start_location.coordinates[0],
        }}
        icon={{
          url: "/starticon.png",
          scaledSize: new window.google.maps.Size(40, 40),
        }}
      />
      {/*<Polyline*/}
      {/*    path={[*/}
      {/*        {lat: trip.start_location.coordinates[1], lng: trip.start_location.coordinates[0], },*/}
      {/*        { lat: trip.end_location.coordinates[1], lng: trip.end_location.coordinates[0] }]}*/}
      {/*    options={{*/}
      {/*        geodesic: true,*/}
      {/*        strokeColor: "red",*/}
      {/*        strokeOpacity: 1.0,*/}
      {/*        strokeWeight: 2,*/}
      {/*    }}*/}
      {/*/>*/}
      <Marker
        key={trip.end_location.coordinates[0]}
        position={{
          lat: trip.end_location.coordinates[1],
          lng: trip.end_location.coordinates[0],
        }}
        icon={{
          url: "/stopicon.png",
          scaledSize: new window.google.maps.Size(40, 40),
        }}
      />

      <Marker
        key={trip._id}
        position={{
          lat: vehicle_location.latitude,
          lng: vehicle_location.longitude,
        }}
        icon={{
          url: "/newmarker.png",
          scaledSize: new window.google.maps.Size(40, 40),
        }}
        rotation={vehicle_location.bearing}
        style={{ transform: [{ rotate: `${vehicle_location.bearing} deg` }] }}
      />
      {/*<Marker position={{lat: 6.621070, lng: 3.503440}}/>*/}
    </GoogleMap>
  ) : (
    <GoogleMap defaultZoom={5} defaultCenter={{ lat: 6.524379, lng: 3.379206 }}></GoogleMap>
  );
};

const WrappedMap = withScriptjs(withGoogleMap(Map));
const apiKey = "AIzaSyDUXyXHX44qbEQNxurwhM5-Du55Ol2im8Q";

const CustomMap = ({ className, data, token, truckLocation }) => {
  useEffect(() => {
    trip = { ...data };
  }, [data]);

  useEffect(() => {
    vehicle_location = { ...truckLocation };
  }, [truckLocation]);

  return (
    <div className={className}>
      <WrappedMap
        googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${apiKey}`}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `100%` }} />}
        mapElement={<div style={{ height: `100%` }} />}
      />
    </div>
  );
};

// const mapStateToProps = ({ trip_token }) =>({ trip_token });
//
// const mapDispatchToProps = reduxFunctions;

export default CustomMap;
