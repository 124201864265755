import React, { useEffect, useState } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import CustomMap from "../components/common/CustomMap";
import { connect } from "react-redux";
import { reduxFunctions } from "../helper/ReduxHelper";
import { Progress } from "antd";
import useWindowSize from "../hooks/useWindowSize";
import "../styles/main.css";
import moment from "moment";
import { setTrip } from "../helper/UtilityHelper";

const LiveTrip = (props) => {
  const [data, setData] = useState(props.trip);

  const getTrip = async () => {
    await props
      .getTrip(props.match.params.id)
      .then(async (res) => {
        setTrip(res.payload.data.trip);
        setData(res.payload.data.trip);
      })
      .catch((error) => {
        // toast.error('Error getting Live trip')
        return error;
      });
  };

  useEffect(() => {
    getTrip();
  }, [props.match.params.id]);
  const size = useWindowSize();

  return (
    data && (
      <div className="live-trip">
        {size.width > 800 ? <ArrowLeftOutlined className="back" onClick={props.history.goBack} /> : ""}
        <CustomMap className="live-trip-map" data={data} />
        <div className="div1">
          <div className="status">
            <p className="p1">Status</p>
            <h2>In Transit</h2>
            <p className="p2">1 day away</p>
            <Progress percent={50} showInfo={false} size={"small"} className="progress" />
          </div>
          <div>
            <p className="p1">Pickup Location</p>
            <h2>{data.start_address}</h2>
            <p className="p2">{moment(data.start_time).format("MMMM DD LT")}</p>
          </div>
          <div>
            <p className="p1">Dropoff Location</p>
            <h2>{data.end_address}</h2>
            <p className="p2">{data.end_time ? moment(data.end_time).format("MMMM DD LT") : "Pending"}</p>
          </div>
          <div>
            <p className="p1">Reference Number</p>
            <h2>{data._id}</h2>
          </div>
        </div>
      </div>
    )
  );
};

const mapStateToProps = ({ user, ongoing_trips, trip }) => ({ user, ongoing_trips, trip });

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(LiveTrip);
