import {
  LayoutOutlined,
  EnvironmentOutlined,
  PieChartOutlined,
  SettingOutlined,
  UserOutlined,
  LockOutlined,
  CreditCardOutlined,
  CarOutlined,
  LogoutOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import { RiDashboardLine, FiTruck } from "react-icons/all";

export const navLinks = [
  {
    key: "/app/dashboard",
    path: `/app/dashboard`,
    title: "Dashboard",
    icon: RiDashboardLine,
    breadcrumb: false,
    submenu: [],
    permission: ["company", "driver"],
  },
  {
    key: "/app/available-trips",
    path: `/app/available-trips`,
    title: "Available Trips",
    icon: FiTruck,
    breadcrumb: false,
    submenu: [],
    permission: ["company", "stand-alone"],
  },
  {
    key: "/app/insights",
    path: `/app/insights`,
    title: "Insights",
    icon: LayoutOutlined,
    breadcrumb: false,
    submenu: [],
    permission: ["company", "driver"],
  },
  {
    key: "/app/trips",
    path: `/app/trips`,
    title: "Trips",
    icon: EnvironmentOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "/app/trips/offline-trip",
        path: `/app/trips/offline-trip`,
        title: "Create Offline Trip",
        permission: ["company"],
      },
      {
        key: "/app/trips/live-trips",
        path: `/app/trips/live-trips`,
        title: "Live Trips",
        permission: ["company", "driver"],
      },
      {
        key: "/app/trips/upcoming-trips",
        path: `/app/trips/upcoming-trips`,
        title: "Upcoming Trips",
        permission: ["company", "driver"],
      },
      {
        key: "/app/trips/past-trips",
        path: `/app/trips/past-trips`,
        title: "Trip History",
        permission: ["company", "driver"],
      },
    ],
    permission: ["company", "driver"],
  },
  {
    key: `/app/vehicle-list`,
    path: `/app/vehicle-list`,
    title: "Vehicles",
    icon: CarOutlined,
    breadcrumb: false,
    submenu: [],
    permission: ["company", "stand-alone"],
  },
  {
    key: "/app/calendar",
    path: `/app/calendar`,
    title: "Calendar",
    icon: CalendarOutlined,
    breadcrumb: false,
    submenu: [],
    permission: ["company", "driver"],
  },
  {
    key: "/app/finance",
    path: `/app/finance`,
    title: "Finance",
    icon: CreditCardOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "/app/finance/wallet",
        path: `/app/finance/wallet`,
        title: "Wallet",
        permission: ["company"],
      },
      {
        key: "/app/finance/payment-history",
        path: `/app/finance/payment-history`,
        title: "Payment History",
        permission: ["company", "driver"],
      },
      {
        key: "/app/finance/payouts",
        path: `/app/finance/payouts`,
        title: "Payouts",
        permission: ["company", "driver"],
      },
    ],
    permission: ["company", "driver"],
  },
  {
    key: "/app/driver-list",
    path: `/app/driver-list`,
    title: "Drivers",
    icon: UserOutlined,
    breadcrumb: false,
    submenu: [],
    permission: ["company"],
  },
  {
    key: `/app/profile`,
    path: `/app/profile`,
    title: "Profile",
    icon: PieChartOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: `/app/profile-user`,
        path: `/app/profile`,
        title: "User Profile",
        permission: ["company", "driver"],
      },
      {
        key: `/app/profile-company`,
        path: `/app/profile-company`,
        title: "Company Profile",
        permission: ["company"],
      },
    ],
    permission: ["company", "driver"],
  },
  {
    key: `/app/settings/change-password`,
    path: `/app/settings`,
    title: "Settings",
    icon: SettingOutlined,
    breadcrumb: false,
    submenu: [],
    permission: ["company", "driver"],
  },
  {
    key: `/logout`,
    path: `/logout`,
    title: "Log Out",
    icon: LogoutOutlined,
    breadcrumb: false,
    submenu: [],
    permission: ["company", "driver"],
  },
];

export const SidebarTree = [
  {
    key: "/app/settings/change-password",
    path: `/app/settings/change-password`,
    title: "Change Password",
    icon: LockOutlined,
  },
  {
    key: "/app/settings/billing",
    path: `/app/settings/billing`,
    title: "Billing",
    icon: CreditCardOutlined,
  },
  // {
  //   key: "/app/settings/notification",
  //   path: `/app/settings/notification`,
  //   title: "Notification",
  //   icon: BellOutlined,
  // },
];
