import React, { useEffect, useState } from "react";
import { Layout, Menu } from "antd";
import { navLinks } from "../../configs/NavConfig";
import CustomIcon from "../util-components/Icon";
import Icon from "@ant-design/icons";
import { Link } from "react-router-dom";
import "../../styles/nav.css";
import { authenticatedUser } from "../../helper/AuthHelper";

const { SubMenu } = Menu;

const { Sider } = Layout;

const SideNav = ({ collapsed, location }) => {
  const [active, setActive] = useState("/app/dashboard");

  const userDetails = authenticatedUser();

  const userType = userDetails?.user_type;
  const isStandAloneDriver = userDetails?.user_type === "driver" && !userDetails?.is_employee;

  useEffect(() => {
    setActive(location.pathname);
  }, [location.pathname]);

  return (
    <Sider trigger={null} collapsible collapsed={collapsed} theme="light" width="20%" className="side-nav">
      <div className="logo" />
      <Menu theme="light" mode="inline" defaultSelectedKeys={[active]} selectedKeys={active}>
        {navLinks.map((menu) =>
          menu.submenu.length === 0 ? (
            (menu?.permission?.includes(userType) ||
              (menu?.permission?.includes("stand-alone") && isStandAloneDriver)) && (
              <Menu.Item
                key={menu.key}
                icon={menu.svg ? <Icon component={menu.svg} /> : <CustomIcon type={menu.icon} size={"large"} />}
              >
                <Link to={menu.path} className="nav-title">
                  {menu.title}
                </Link>
              </Menu.Item>
            )
          ) : (
            <SubMenu key={menu.key} icon={<CustomIcon type={menu.icon} />} className="nav-title" title={menu.title}>
              {menu.submenu.map(
                (sub) =>
                  sub?.permission?.includes(userType) && (
                    <Menu.Item key={sub.key}>
                      <Link to={sub.path} className="nav-title">
                        {sub.title}
                      </Link>
                    </Menu.Item>
                  )
              )}
            </SubMenu>
          )
        )}
      </Menu>
    </Sider>
  );
};

export default SideNav;
