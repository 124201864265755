import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Icon, { ExclamationCircleOutlined } from "@ant-design/icons";

const NotificationBar = () => {
  return (
    <Container>
      <div className="inner">
        <span className="icon">
          <Icon component={ExclamationCircleOutlined} />
        </span>
        <p>
          You need to upload your Driver's License. <Link to="/app/profile"> Click here to upload </Link>
        </p>
      </div>
    </Container>
  );
};

const Container = styled.div`
  padding: 10px 0px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e27626;

  .inner {
    display: flex;
    align-items: end;
    gap: 6px;
  }

  .icon {
    color: white;
  }

  p,
  a {
    color: white;
    font-size: 14px;
  }
  a {
    text-decoration: underline;
  }
`;

export default NotificationBar;
