import { PAYOUT_STATES } from "../constants/Enums";

export const formatPayoutState = (state) => {
  switch (state) {
    case PAYOUT_STATES.PAID:
      return "Paid";

    case PAYOUT_STATES.FAILED:
      return "Failed";

    case PAYOUT_STATES.REVERSED:
      return "Reversed";

    case PAYOUT_STATES.IN_PROGRESS:
      return "In Progress";

    default:
      return "In Progress";
  }
};
