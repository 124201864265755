import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Avatar, Button, Image, Progress, Spin, Tooltip } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { reduxFunctions } from "../helper/ReduxHelper";
import "../styles/main.css";
import moment from "moment";
import { getProgress } from "../helper/TripHelper";
import GoMap from "./common/GoMap";
import StatusBadge from "./common/StatusBadge";
import { TRIP_STATE, VEHICLE_TYPES } from "../constants/Enums";
import { formatMoney, getKeyByValue } from "../helper/UtilityHelper";
import { IoMdCopy } from "react-icons/io";
import { authenticatedUser } from "../helper/AuthHelper";
import loadingIcon from "./common/loadingIcon";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";

const TripItem = ({
  data,
  available,
  getUserWithId,
  getVehicle,
  getTrackersHealth,
  updateTripStatus,
  loadingTripUpdate,
  getUpcomingTrips,
}) => {
  const [showMap, setShowMap] = useState(false);
  // eslint-disable-next-line
  const [truckLocation, setTruckLocation] = useState({});
  const [tripStatus, setTripStatus] = useState(null);
  const [driver, setDriver] = useState({});
  const [driverLoading, setDriverLoading] = useState(false);
  const [vehicle, setVehicle] = useState({});
  const [vehicleLoading, setVehicleLoading] = useState(false);
  const [trackerHealth, setTrackerHealth] = useState("N/A");

  const userDetail = authenticatedUser();
  const userType = userDetail.user_type;
  const isDriver = userType === "driver";
  const isCompany = userType === "company";
  const isStandAloneDriver = userType === "driver" && !userDetail?.is_employee;

  const history = useHistory();

  const { PENDING, ACCEPTED } = TRIP_STATE;

  const mapToggle = (id) => {
    setShowMap(!showMap);
  };

  const getDriver = (id) => {
    setDriverLoading(true);
    if (id) {
      getUserWithId(id)
        .then((res) => {
          setDriver(res.payload.data.user);
          setDriverLoading(false);
          return true;
        })
        .catch((error) => {
          setDriverLoading(false);
          return error;
        });
    }
  };

  const _getVehicle = (id) => {
    setVehicleLoading(true);
    if (id) {
      getVehicle(id)
        .then((res) => {
          setVehicleLoading(false);
          setVehicle(res.payload.data.vehicle);
          return;
        })
        .catch((error) => {
          setVehicleLoading(false);
          return error;
        });
    }
  };

  const _getTrackersHealth = (tracking_id) => {
    getTrackersHealth([tracking_id])
      .then((res) => {
        setTrackerHealth(res?.payload?.data[0]?.status);
      })
      .catch((error) => {
        return error;
      });
  };

  const handleAccept = () => {
    if (isDriver) {
      updateTripStatus(userDetail?._id, data?._id, "ACCEPTED")
        .then((res) => {
          toast.success("Trip accepted successfully");
          history.push("/app/trips/upcoming-trips");
        })
        .catch((error) => {
          toast.error("An error occurred");
          return error;
        });
    } else {
      history.push(`/app/available-drivers/${data._id}`);
    }
  };

  const fetchUpcomingTrips = async () => {
    await getUpcomingTrips()
      .then((res) => {
        // setData([...res.payload.data.trips]);
      })
      .catch((error) => {
        toast.error("Error getting upcoming trips data");
        return error;
      });
  };

  const updateTrip = () => {
    updateTripStatus(data?.driver?._id, data?._id, TRIP_STATE.CANCELLED)
      .then((res) => {
        toast.success("Trip cancelled successfully");
        fetchUpcomingTrips();
      })
      .catch((error) => {
        toast.error(`${error?.error?.response?.data?.message || "An error occurred"}`);
        return error;
      });
  };

  const cancelTrip = () => {
    confirmAlert({
      title: "Confirm Cancellation",
      message: "Are you sure you want to cancel this trip?. This action cannot be reversed",
      buttons: [{ label: "Yes", onClick: () => updateTrip() }, { label: "No" }],
    });
  };

  useEffect(() => {
    getDriver(data?.driver?._id ? data?.driver?._id : data?.driver);
    _getVehicle(data?.vehicle?._id ? data?.vehicle?._id : data?.vehicle);
  }, [data]);

  useEffect(() => {
    if (vehicle.tracking_id) {
      _getTrackersHealth(vehicle.tracking_id);
    }
  }, [vehicle]);

  const canCancel = data?.state?.value === PENDING || data?.state?.value === ACCEPTED;

  return (
    <div className="trip-item-container">
      {available ? (
        <div className="second-trip-item">
          <div className="div-item alt">
            <div>
              <h1>{getProgress(data?.state.value).title}</h1>
              <p>{moment(`${data.start_time}`).format("MMMM Do YYYY, h:mmA")}</p>
            </div>
            <div className="tdo">
              <p>TDO IMAGE</p>
              <Avatar shape="square" size={"default"} src={<Image src={data.tdo?.tdo_image} />} />
            </div>
            <p
              onClick={() => mapToggle(data._id)}
              style={{
                textDecoration: "underline",
                color: "#f77120",
                marginTop: "3vh",
                marginBottom: "3vh",
                cursor: "pointer",
              }}
              className="all-button"
              type="primary"
              size={"small"}
            >
              {!showMap ? "View Map" : "Close Map"}
            </p>
          </div>
          <div className="second-item">
            <p>From</p>
            <h1>{data.start_address}</h1>
            <p>To</p>
            <h1>{data.end_address}</h1>
            <div className="sub-div">
              {data.container_number && (
                <div>
                  <p>Container ID</p>
                  <p>{data.container_number}</p>
                </div>
              )}
              <div>
                <p>Vehicle Type</p>
                <p>{getKeyByValue(VEHICLE_TYPES, data.item_size)}</p>
              </div>
              <div>
                <p>Offload Duration</p>
                <p>{moment.duration(data.offloading_duration, "milliseconds").asDays()}days</p>
              </div>
              <div>
                <p>Tonnage</p>
                <p>{data.tonnage} tonnes</p>
              </div>
            </div>

            <div style={{ marginTop: "10px" }}>
              <p>Estimated Price</p>
              <h3 style={{ fontWeight: "600" }}>
                {formatMoney({ value: data?.cost_id?.price, money: true })} -{" "}
                {formatMoney({ value: data?.cost_id?.price_upper_bound, money: true })}
              </h3>
            </div>
          </div>
          <div className="div-item" style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button className="all-button" type="primary" size={"large"} onClick={handleAccept}>
              {loadingTripUpdate ? <Spin indicator={loadingIcon} /> : " Accept Trip"}
            </Button>
          </div>
        </div>
      ) : (
        <div>
          <div className="trip-item">
            <div className="div1">
              <h2 className="h1">{tripStatus ? getProgress(tripStatus).title : getProgress(data.state.value).title}</h2>
              <p className="date">{moment(`${data.start_time}`).format("MMMM Do YYYY, h:mmA")}</p>
              <Progress
                percent={tripStatus ? getProgress(tripStatus).percentage : getProgress(data.state.value).percentage}
                showInfo={false}
                className="progress"
                size={"small"}
              />
            </div>
            <div className="div2">
              <span className="address">From</span>
              <h2 className="h1">{data.start_address}</h2>
            </div>
            <div className="div3">
              <span className="address">To</span>
              <h2 className="h1">{data.end_address}</h2>
            </div>
            <Button onClick={() => mapToggle(data._id)} type={"primary all-button"}>
              {!showMap ? (
                <>
                  View map <DownOutlined />
                </>
              ) : (
                <>
                  Close map <UpOutlined onClick={mapToggle} />
                </>
              )}
            </Button>
          </div>
          <Info>
            <div className={"item"}>
              <h4>Driver</h4>
              <p>{driverLoading ? "N/A" : `${driver?.given_name} ${driver?.family_name}`}</p>
            </div>
            <div className={"item"}>
              <h4>Vehicle Type</h4>
              <p>{getKeyByValue(VEHICLE_TYPES, data.item_size)}</p>
            </div>
            <div className={"item"}>
              <h4>Plate number</h4>
              <p>{vehicleLoading ? "N/A" : `${vehicle?.plate_number}`}</p>
            </div>
            <div className={"item"}>
              <h4>Tonnage</h4>
              <p>{data?.tonnage}</p>
            </div>

            {data.container_number && (
              <div className={"item"}>
                <h4>Container ID</h4>
                <p>{data?.container_number}</p>
              </div>
            )}
            {data.tracking_id && (
              <div className={"item"}>
                <h4>Tracking ID</h4>

                <div className="tracking">
                  <p>{data.tracking_id}</p>
                  <Tooltip title="copy tracking id">
                    <IoMdCopy
                      color={"#adadad"}
                      size={17}
                      className={"icon"}
                      onClick={() => navigator.clipboard.writeText(data.tracking_id)}
                    />
                  </Tooltip>
                </div>
              </div>
            )}
            <div className={"item"}>
              <h4>Tracker Status</h4>
              <StatusBadge status={trackerHealth} />
            </div>
          </Info>

          {(isCompany || isStandAloneDriver) && canCancel && (
            <CancelBtn>
              <p type="primary all-button" onClick={cancelTrip}>
                Cancel Trip
              </p>
            </CancelBtn>
          )}
        </div>
      )}
      {showMap && (
        <div style={{ padding: "0 30px 20px" }}>
          <GoMap
            className="upcoming-item-map"
            data={data}
            truckLocation={truckLocation}
            setTripStatus={setTripStatus}
          />
        </div>
      )}
    </div>
  );
};

const Info = styled.div`
  display: flex;
  padding: 0 30px 10px;
  flex-wrap: wrap;
  width: 100%;

  .item {
    flex: 1;

    p {
      font-weight: 600;
      font-size: 15px;
    }

    h4 {
      color: #6e6e6e;
    }
  }

  .icon {
    cursor: pointer;
  }
  .tracking {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  @media (max-width: 600px) {
    display: block;
  }
`;

const CancelBtn = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 30px 20px 0;
  color: red;

  p {
    cursor: pointer;
  }
`;

const mapStateToProps = ({ user, ongoing_trips, loadingTripUpdate }) => ({ user, ongoing_trips, loadingTripUpdate });

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(TripItem);
