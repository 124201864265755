import React from "react";
import LoginForm from "../../components/LoginForm";
import { getUser } from "../../helper/JwtHelper";
import { Redirect } from "react-router-dom";
import AuthLayout from "../../components/layout-components/AuthLayout";

const LoginScreen = (props) => {
  const handleLogin = () => {
    props.history.push("/app");
  };

  if (getUser()) return <Redirect to="/app" />;

  return (
    <AuthLayout>
      <LoginForm handleLogin={handleLogin} />
    </AuthLayout>
  );
};

export default LoginScreen;
