import React from "react";
import styled from "styled-components";
import { BiCurrentLocation } from "react-icons/bi";

const StatusBadge = ({ status, form }) => {
  return (
    <Container status={status}>
      {form && <BiCurrentLocation />} <span>{status}</span>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;

  span {
    margin-left: 5px;
    background: #d4d433;
    background: ${({ status }) => status === "online" && "#0c6e0c"};
    background: ${({ status }) => status === "valid" && "#0c6e0c"};
    background: ${({ status }) => status === "faulty" && "#e71818"};
    background: ${({ status }) => status === "invalid" && "#e71818"};
    background: ${({ status }) => status === "N/A" && "#7c7c7c"};
    color: white;
    font-size: 10px;
    padding: 1px 8px;
    border-radius: 5px;
  }
`;

export default StatusBadge;
