import React from "react";
import { Button, Form, Input, Modal, Spin } from "antd";
import CustomSelect from "./Select";
import { BANKS } from "../../configs/common-options";
import loadingIcon from "../common/loadingIcon";
import "../../styles/main.css";

const AddBankModal = (props) => {
  return (
    <div>
      <Modal
        title="Add New Bank"
        className="add-info-modal"
        visible={props.isModalVisible}
        onOk={props.handleOk}
        onCancel={props.handleCancel}
        footer={[
          <Button className="modal-button" key="back" onClick={props.handleCancel} size={"large"}>
            Cancel
          </Button>,
          <Button
            disabled={!props.accountInfo.account_name}
            key="submit"
            className="modal-button"
            type="primary"
            onClick={props.handleAdd}
            size={"large"}
          >
            {props.loading ? <Spin indicator={loadingIcon} /> : <span>Add</span>}
          </Button>,
        ]}
      >
        <Form layout="vertical" className="modal-form">
          <Form.Item label="Bank Name" name="bank_name" className="modal-label">
            <div className="selectContainer2">
              <CustomSelect
                options={BANKS}
                size="large"
                name="bank_name"
                handleChange={props.handleSelectChange}
                value={props.accountInfo.bank_name}
                bordered={false}
              />
            </div>
          </Form.Item>
          <Form.Item label="Account Number" className="modal-label">
            <Input
              className="modal-input"
              name="account_number"
              value={props.accountInfo.account_number}
              onChange={props.handleInfoChange}
            />
          </Form.Item>
          <p style={{ textAlign: "right" }}>{props.verifying ? "Validating..." : props.accountInfo?.account_name}</p>
          {/*<Form.Item*/}
          {/*    label="Bank code"*/}
          {/*    className='modal-label'*/}
          {/*>*/}
          {/*    <Input className='modal-input' name="bank_code" value={props.accountInfo.bank_code} onChange={props.handleInfoChange}/>*/}
          {/*</Form.Item>*/}
        </Form>
      </Modal>
    </div>
  );
};

export default AddBankModal;
