import React from "react";
import { Button, InputNumber, Spin } from "antd";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import { reduxFunctions } from "../../helper/ReduxHelper";
import { formatMoney } from "../../helper/UtilityHelper";
import { usePaystackPayment } from "react-paystack";
import loadingIcon from "../common/loadingIcon";
import { authenticatedCompany } from "../../helper/AuthHelper";
import { envConfig } from "../../config";

const InsufficientBalanceModal = (props) => {
  const company = authenticatedCompany();

  const balance = company?.wallet_id?.balance || 0;

  const config = {
    reference: new Date().getTime().toString(),
    email: company?.email,
    amount: props.fundAmount * 100, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
    publicKey: envConfig.REACT_APP_PAYSTACK_PUBLIC_KEY,
  };

  const initializePayment = usePaystackPayment(config);

  const fundWalletSuccessfull = () => {
    setTimeout(() => {
      props.setProcessingModalOpen(false);
      props.setFundAmount("");
    }, 3000);
  };

  const fundWallet = async (data) => {
    props.setOpenWalletModal(false);
    props.setProcessingModalOpen(true);
    const payload = { reference: data?.reference };
    try {
      const res = await props.fundWallet(company?._id, payload);
      if (res?.error) {
        throw new Error(res?.error);
      }

      fundWalletSuccessfull();
      return;
    } catch (error) {
      props.setProcessingModalOpen(false);
      toast.error("An error occured, please try again");
      return error;
    }
  };

  const onPaymentSuccess = (reference) => {
    fundWallet(reference);
  };

  const onPaymentClose = () => {};

  const handleFundWallet = () => {
    if (!props.fundAmount) return toast.error("Please enter a valid amount");
    if (+props.fundAmount < 5000) return toast.error("Minimum topup amount is ₦5000");

    initializePayment(onPaymentSuccess, onPaymentClose);
  };

  return (
    <Container>
      {props.topup ? (
        <div className="topup-container">
          <div>
            <h2>{formatMoney({ value: balance, money: true })}</h2>
            <Link to="/app/finance/wallet">Wallet Balance</Link>
          </div>

          <div style={{ width: "100%", margin: "20px 0px" }}>
            <h3>Enter top-up amount</h3>
            <div>
              <div className="input-container">
                <div className="currency-container">
                  <img src="/9ja-flag.png" alt="currency" />
                  <p>NGN</p>
                </div>

                <InputNumber
                  name="props.fundAmount"
                  value={props.fundAmount}
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
                  onChange={(value) => props.setFundAmount(value)}
                  placeholder="Minimum of ₦5000"
                  controls={false}
                  style={{ width: "100%", padding: "6px", borderRadius: "5px", height: "100%" }}
                />
              </div>
            </div>
          </div>
          <Button type="primary" onClick={handleFundWallet}>
            {props.fund_wallet_loading ? <Spin indicator={loadingIcon} /> : "Fund Wallet"}
          </Button>
        </div>
      ) : (
        <div className="content-container">
          <img src="/insufficient-balance.svg" alt="Empty Transactions" />
          <h2>Oops, Insufficient Balance</h2>
          <p>You do not have sufficient balance in your wallet to create a trip. Please top up to proceed</p>
          <Button type="primary" onClick={() => props.setTopup(!props.topup)}>
            Top-up Wallet
          </Button>
        </div>
      )}
    </Container>
  );
};

const Container = styled.div`
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;

  .content-container {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }

  img {
    max-height: 80px;
  }

  h2 {
    margin: 10px 0 0px 0;
    color: #000000;
    padding: 0;
  }

  p {
    margin: 5px 0;
    color: #667085;
  }

  button {
    width: 100%;
    height: 42px;
    border-radius: 5px;
  }

  .topup-container {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;

    h2 {
      font-size: 24px;
      margin: 0;
    }

    h3 {
      text-align: left;
      font-weight: 400;
    }

    a {
      color: "#E27626";
      text-decoration: underline;
      font-size: 14px;
    }

    .input-container {
      width: 100%;
      margin-top: 10px;
      display: flex;
      align-items: center;
      gap: 10px;
    }
    .currency-container {
      background: #edf5ff;
      padding: 8px 10px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
`;

const mapStateToProps = ({ fundWallet, fund_wallet_loading }) => ({
  fundWallet,
  fund_wallet_loading,
});

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(InsufficientBalanceModal);
