import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Spin } from "antd";
import { connect } from "react-redux";
import { reduxFunctions } from "../helper/ReduxHelper";
import OtpInput from "react-otp-input";
import "../styles/auth.css";
import loadingIcon from "../components/common/loadingIcon";
import { toast } from "react-toastify";
import AuthLayout from "../components/layout-components/AuthLayout";
import styled from "styled-components";

const EmailVerification = (props) => {
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const handleSubmit = () => {
    if (otp.length < 6) {
      toast.error("Verification code incomplete");
      return;
    }
    setLoading(true);
    props
      .verifyUser(parseInt(otp))
      .then((res) => {
        setLoading(false);
        history.push("/app");
        toast.success("Verification Successful!");
      })
      .catch((error) => {
        toast.error("Invalid Code");
        setLoading(false);
        return error;
      });
  };

  const handleResend = () => {
    props
      .resendVerificationCode()
      .then((res) => {
        toast.success("Code sent!");
      })
      .catch((error) => {
        toast.error("Error resending code");
        return error;
      });
  };

  return (
    <AuthLayout>
      <Container>
        <h2 className="">Enter Verification Code</h2>
        <div className="">
          <p>Kindly enter the code sent to your email or phone number</p>
          <OtpInput
            value={otp}
            onChange={(value) => setOtp(value)}
            numInputs={6}
            separator="-"
            inputStyle="input"
            containerStyle="input-container"
            focusStyle="focus-input"
            shouldAutoFocus
            isInputNum
          />
        </div>
        <Button onClick={handleSubmit} className="btn" block type="primary" size={"large"}>
          {loading ? <Spin indicator={loadingIcon} /> : <span>Submit</span>}
        </Button>
        <p className="resend-otp">
          Didn't get OTP?<span onClick={handleResend}>Resend</span>
        </p>
      </Container>
    </AuthLayout>
  );
};

const Container = styled.div`
  a,
  p {
    font-size: 14px;
  }

  .input {
    border-radius: 4px;
    margin: 0px 10px;
    font-color: #000;
    font-size: 18px;
    width: 2.5rem !important;
    height: 2.5rem !important;
    border: 1px solid #ccc !important;
  }

  .input-container {
    display: flex;
    justify-content: center;
    margin: 40px auto;
  }

  .focus-input {
    outline: 1px solid #e27725;
  }

  .resend-otp {
    margin-top: 10px;

    span {
      color: #e27725;
      margin-left: 5px;
      text-decoration: underline;
    }
  }

  .check {
    display: flex;
    gap: 10px;
  }

  .btn {
    padding: 12px;
    height: 3rem;
    border-radius: 8px;
  }
`;

const mapStateToProps = ({ user }) => ({ user });

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(EmailVerification);
