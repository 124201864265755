import React, { useState } from "react";
import styled from "styled-components";
import { reduxFunctions } from "../../helper/ReduxHelper";
import { connect } from "react-redux";
import { Steps } from "antd";
import ContainerDetailsForm from "../../components/tracking/ContainerDetailsForm";
import LocationForm from "../../components/tracking/LocationForm";
import CustomerProfile from "../../components/tracking/CustomerProfile";
import TerminalList from "../../components/tracking/TerminalList";
import AssignDriver from "../../components/tracking/AssignDriver";

const { Step } = Steps;

const Index = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [data, setData] = useState({
    cargo_type: "CONTAINER",
    item_size: "",
    is_container_owner: "",
    tonnage: "",
    trip_category: "OFFLINE_TRIP",
    container_number: "",
    startAddress: "",
    endAddress: "",
    startCoordinates: "",
    endCoordinates: "",
    dropOffCoordinates: "",
    startDate: "",
    dropOffAddress: "",
  });
  const [visibleTerminalList, setVisibleTerminalList] = useState(false);
  const [terminalOpener, setTerminalOpener] = useState("");

  const handleChange = (e) => {
    const { name, type, value, checked } = e.target;
    const val = type === "checkbox" ? checked : value;
    setData({ ...data, [name]: val });
  };

  const handleStepChange = (step) => {
    const stepElement = document.getElementById("icon");
    stepElement.scrollIntoView();
    setCurrentStep(step);
  };

  return (
    <Inner>
      <Head id={"icon"}></Head>
      <Steps size="large" current={currentStep} className={"steps"} id={"steps"}>
        <Step title="Container Details" />
        <Step title="Select Location" />
        <Step title="Customer Information" />
        <Step title="Assign Driver" />
      </Steps>
      <div className={"content"}>
        {currentStep === 0 && (
          <ContainerDetailsForm
            data={data}
            handleChange={handleChange}
            setData={setData}
            setCurrentStep={handleStepChange}
          />
        )}
        {currentStep === 1 && (
          <LocationForm
            data={data}
            handleChange={handleChange}
            setData={setData}
            setCurrentStep={handleStepChange}
            setVisibleTerminalList={setVisibleTerminalList}
            setTerminalOpener={setTerminalOpener}
          />
        )}
        {currentStep === 2 && (
          <CustomerProfile
            data={data}
            handleChange={handleChange}
            setData={setData}
            setCurrentStep={handleStepChange}
          />
        )}
        {currentStep === 3 && (
          <AssignDriver data={data} handleChange={handleChange} setData={setData} setCurrentStep={handleStepChange} />
        )}
      </div>

      <TerminalList
        visible={visibleTerminalList}
        closeModal={() => setVisibleTerminalList(false)}
        setVisibleTerminalList={setVisibleTerminalList}
        data={data}
        setData={setData}
        terminalOpener={terminalOpener}
      />
    </Inner>
  );
};

const Inner = styled.div`
  overflow: auto;
  background: white;
  min-height: 400px;
  padding: 30px;

  .steps {
    width: 90%;
    margin: 0 auto;
    padding: 20px 0 50px;
  }

  .content {
    width: 85%;
    margin: 0 auto;
  }

  @media (max-width: 500px) {
    padding: 10px 0px;
  }
  @media (max-width: 300px) {
    .content {
      width: 100%;
    }
  }
`;
const Head = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 30px;

  .icon {
    cursor: pointer;
  }
`;

const mapStateToProps = ({ user }) => ({ user });

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(Index);
