import React from "react";
import CustomSelect from "../../components/common/Select";
import { toast } from "react-toastify";
import "../../styles/main.css";
import { Button, Form } from "antd";
import { truckOptions } from "../../constants/Enums";

const vehicleType = ({ setVehicleType, vehicleType, handleStepChange, data, setData }) => {
  const handleSelect = (value) => {
    setVehicleType(value);
    setData({ ...data, size: value });
  };

  const handleNext = () => {
    if (vehicleType === "") {
      toast.error("Select a vehicle type");
      return;
    }
    handleStepChange(2);
  };

  return (
    <div className="vehicle-form">
      <h2>Vehicle Type</h2>
      <Form layout="vertical">
        <Form.Item label="Choose Type" className="label">
          <div className="selectContainer2">
            <CustomSelect
              options={truckOptions}
              size="large"
              handleChange={handleSelect}
              value={vehicleType}
              bordered={false}
            />
          </div>
          <div className="buttons">
            <Button className="b2 alt-button" size={"large"} onClick={() => handleStepChange(0)}>
              Back
            </Button>
            <Button className=" all-button b2" type="primary" size={"large"} onClick={handleNext}>
              Next
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default vehicleType;
