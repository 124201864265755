export const authenticatedUser = () => {
  if (typeof window === "undefined") {
    return false;
  }
  if (localStorage.getItem("user")) {
    return JSON.parse(localStorage.getItem("user"));
  }
  return false;
};

export const authenticatedCompany = () => {
  if (typeof window === "undefined") {
    return false;
  }
  if (localStorage.getItem("company") !== "undefined") {
    return JSON.parse(localStorage.getItem("company"));
  }
  return false;
};
