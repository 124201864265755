import React, { useState } from "react";
import { Button, Form, Input, Spin } from "antd";
import { connect } from "react-redux";
import { reduxFunctions } from "../../helper/ReduxHelper";
import loadingIcon from "../../components/common/loadingIcon";
import "../../styles/main.css";
import { toast } from "react-toastify";

const ChangePassword = (props) => {
  const [data, setData] = useState({ oldPassword: "", newPassword: "", confirmPassword: "" });
  const [loading, setLoading] = useState(false);

  const { oldPassword, newPassword, confirmPassword } = data;

  const _startLoading = () => {
    setLoading(true);
  };

  const _stopLoading = () => {
    setLoading(false);
  };

  const handleChange = ({ currentTarget: input }) => {
    const newData = { ...data };
    newData[input.name] = input.value;
    setData(newData);
  };

  const hasSixCharacters = newPassword?.trim()?.length >= 6;
  const hasUpperCase = /[A-Z]/.test(newPassword);
  const hasLowerCase = /[a-z]/.test(newPassword);
  const hasDigit = /\d/.test(newPassword);

  // eslint-disable-next-line
  const hasSpecialCharacter = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(newPassword);

  const handleSubmit = async () => {
    if (oldPassword === newPassword || oldPassword === confirmPassword)
      return toast.error("New password cannot be the same with the old password");
    if (newPassword !== confirmPassword) return toast.error("Entered passwords are not the same");
    if (!hasSixCharacters) return toast.error("Password must contain at least 6 characters");
    if (!hasUpperCase) return toast.error("Password must contain at least 1 uppercase character");
    if (!hasLowerCase) return toast.error("Password must contain at least 1 lowercase character");
    if (!hasDigit) return toast.error("Password must contain at least 1 digit");
    if (!hasSpecialCharacter) return toast.error("Password must contain at least 1 special character");

    if (newPassword === confirmPassword) {
      _startLoading();
      await props
        .changePassword(oldPassword, newPassword)
        .then(async (res) => {
          toast.success("Password Changed!");
          setData({ oldPassword: "", newPassword: "", confirmPassword: "" });
          _stopLoading();
        })
        .catch((error) => {
          //   setData({ oldPassword: "", newPassword: "", confirmPassword: "" });
          _stopLoading();
          toast.error(error.error.response.data.message);
          return error;
        });
    }
  };

  return (
    <div className="change_password">
      <h2>Change Password</h2>
      <Form
        layout="vertical"
        name="login-form"
        // onFinish={handleSubmit}
        className="change_password-form"
      >
        <Form.Item label="Current Password" className="label">
          <Input.Password
            size={"large"}
            className="input"
            name="oldPassword"
            value={data.oldPassword}
            onChange={handleChange}
          />
        </Form.Item>
        <Form.Item label="New Password" className="label">
          <Input.Password
            size={"large"}
            className="input"
            name="newPassword"
            value={data.newPassword}
            onChange={handleChange}
          />
        </Form.Item>
        <Form.Item label="Confirm Password" className="label">
          <Input.Password
            size={"large"}
            className="input"
            name="confirmPassword"
            value={data.confirmPassword}
            onChange={handleChange}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" className="button all-button" size={"large"} onClick={handleSubmit}>
            {loading ? <Spin indicator={loadingIcon} /> : <span>Submit</span>}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

const mapStateToProps = ({ user, vehicle }) => ({ user, vehicle });

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
