import React, { useEffect, useState } from "react";
import { Layout, Menu, Avatar, Tooltip } from "antd";
import { Link } from "react-router-dom";
import { HiMenuAlt3 } from "react-icons/hi";
import GoLogo from "../../assets/svgs/go-logo.svg";
import { MenuUnfoldOutlined, MenuFoldOutlined, UserOutlined } from "@ant-design/icons";
import "../../styles/nav.css";
import Logo2 from "../../assets/svgs/go-logo.svg";
import useWindowSize from "../../hooks/useWindowSize";
import Complete from "./AutoComplete";
import { formatMoney } from "../../helper/UtilityHelper";
import { authenticatedCompany, authenticatedUser } from "../../helper/AuthHelper";
import { connect } from "react-redux";
import { reduxFunctions } from "../../helper/ReduxHelper";
import { VERIFICATION_STATUSES } from "../../constants/Enums";

const { Header } = Layout;

const TopNav = ({ toggle, collapsed, showDrawer, history, getCompany, fund_wallet_loading, create_trip_loading }) => {
  const [companyDetails, setCompanyDetails] = useState(authenticatedCompany());
  const userDetails = authenticatedUser();

  const userType = userDetails?.user_type;
  const is_employee = userDetails?.is_employee;
  const { VERIFIED } = VERIFICATION_STATUSES;
  const license_status = userDetails?.drivers_license?.verification_status;

  const getUserCompany = async () => {
    await getCompany()
      .then((res) => {
        const userCompany = { ...res?.payload?.data?.companies[0] };
        localStorage.setItem("company", JSON.stringify(userCompany));
        setCompanyDetails(userCompany);
      })
      .catch((error) => {
        return error;
      });
  };

  useEffect(() => {
    //populate company object in localstorage for an employed driver
    if (!companyDetails && userType !== "company" && is_employee) {
      getUserCompany();
    }
  }, []);
  useEffect(() => {
    setCompanyDetails(authenticatedCompany());
  }, [fund_wallet_loading, create_trip_loading]);

  const size = useWindowSize();
  return (
    <Header className="top-nav-header">
      {size.width >= 1000 && (
        <Menu mode="horizontal" defaultSelectedKeys={["2"]} theme="light" className="top-nav">
          <Menu className="menu1">
            <div key="1" className="go-logo" onClick={() => history.push("/app/dashboard")}>
              <img src={Logo2} alt="Go Truck" />
            </div>

            <div key="2" className={"side-bar-toggle"}>
              {collapsed ? (
                <Tooltip title="Toggle side menu">
                  <MenuUnfoldOutlined onClick={toggle} style={{ fontSize: "25px" }} />
                </Tooltip>
              ) : (
                <Tooltip title="Toggle side menu">
                  <MenuFoldOutlined onClick={toggle} style={{ fontSize: "25px" }} />
                </Tooltip>
              )}
            </div>
          </Menu>

          {userType === "company" && (
            <Menu className="menu2">
              <div className="user-info">
                <p className="p1" style={{ color: "#8F90A6" }}>
                  Wallet Balance
                </p>
                <p className="p1" style={{ fontWeight: "semibold" }}>
                  {formatMoney({ value: companyDetails?.wallet_id?.balance || 0, money: true })}
                </p>
              </div>
            </Menu>
          )}

          <Menu className="menu2">
            {/*<Menu.Item className='menu-item picture'>*/}
            <Link to="/app/profile">
              <Avatar
                src={userDetails.profile_picture ? userDetails.profile_picture.original : ""}
                icon={<UserOutlined />}
              />
            </Link>
            {/*</Menu.Item>*/}

            {userType === "company" ? (
              <div className="user-info">
                <p className="p1"> {companyDetails && companyDetails.name ? companyDetails.name : ""}</p>
                <p className="p2">{userDetails && userDetails.full_name ? userDetails.full_name : ""}</p>
              </div>
            ) : (
              <div className="user-info">
                <div className="" style={{ display: "flex", alignItems: "center" }}>
                  <p className="p1" style={{ textTransform: "capitalize" }}>
                    {userDetails && userDetails.full_name ? userDetails.full_name : ""}
                  </p>
                  {userType === "driver" && (
                    <p style={{ marginLeft: "6px" }}>
                      {/* <Tooltip placement="left" title="Verified"> */}
                      {license_status && (
                        <img
                          src={license_status === VERIFIED ? "/verified.svg" : "/review.svg"}
                          alt="status"
                          style={{ maxWidth: "15px" }}
                        />
                      )}
                      {/* </Tooltip> */}
                    </p>
                  )}
                </div>
                <p className="p2"> {companyDetails && companyDetails.name ? companyDetails.name : ""}</p>
              </div>
            )}
          </Menu>
        </Menu>
      )}
      {size.width < 1000 && (
        <div className="top-nav-mobile">
          <img src={GoLogo} alt="go-logo" onClick={() => history.push("/app/dashboard")} />
          {size.width > 700 && <Complete history={history} userType={userType} />}
          <div>
            {userType === "company" ? (
              <div className="user-info">
                <p className="p1"> {companyDetails && companyDetails.name ? companyDetails.name : ""}</p>
                <p className="p2">{userDetails && userDetails.full_name ? userDetails.full_name : ""}</p>
              </div>
            ) : (
              <div className="user-info">
                <p className="p1">{userDetails && userDetails.full_name ? userDetails.full_name : ""}</p>
                <p className="p2"> {companyDetails && companyDetails.name ? companyDetails.name : ""}</p>
              </div>
            )}
            <Link to="/app/profile">
              <Avatar
                src={userDetails.profile_picture ? userDetails.profile_picture.original : ""}
                icon={<UserOutlined />}
              />
            </Link>
            <HiMenuAlt3 onClick={showDrawer} className="mobile-menu" />
          </div>
        </div>
      )}
    </Header>
  );
};

const mapStateToProps = ({ fund_wallet_loading, create_trip_loading }) => ({
  fund_wallet_loading,
  create_trip_loading,
});

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(TopNav);
