import React, { useEffect, useState } from "react";
import moment from "moment";
import { Calendar, Badge, Modal, Spin } from "antd";
import "../styles/main.css";
import { reduxFunctions } from "../helper/ReduxHelper";
import { connect } from "react-redux";
import { formatTripsForCalendar } from "../helper/UtilityHelper";
import { getContent } from "../helper/UtilityHelper";
import { Link } from "react-router-dom";

const CalendarScreen = (props) => {
  const [value, setValue] = useState(moment());
  const [selectedValue, setSelectedValue] = useState(moment());
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [upcomingTrips, setUpcomingTrips] = useState([]);
  const [pastTrips, setPastTrips] = useState([]);
  const [ongoingTrips, setOngoingTrips] = useState([]);
  const [contents, setContents] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getUpcomingTrips();
    getOngoingTrips();
    getPastTrips();
  }, []);

  const getUpcomingTrips = async () => {
    setLoading(true);
    await props
      .getUpcomingTrips()
      .then((res) => {
        setUpcomingTrips([...res.payload.data.trips]);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        return error;
      });
  };

  const getOngoingTrips = async () => {
    setLoading(true);
    await props
      .getOngoingTrips()
      .then((res) => {
        setOngoingTrips([...res.payload.data.trips]);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        return error;
      });
  };

  const getPastTrips = async () => {
    setLoading(true);
    await props
      .getPastTrips()
      .then((res) => {
        setPastTrips([...res.payload.data.trips]);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        return error;
      });
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const getCalendarList = (value) => {
    // console.log(value)
    let past = formatTripsForCalendar(pastTrips, value, "success");
    let upcoming = formatTripsForCalendar(upcomingTrips, value, "warning");
    let ongoing = formatTripsForCalendar(ongoingTrips, value, "processing");
    // console.log(past.concat(upcoming).concat(ongoing), 'calendar screen')
    // console.log(past, 'past')
    // console.log(upcoming, 'upcoming')
    // console.log(ongoing, 'ongoing')
    return past.concat(upcoming).concat(ongoing);
  };

  const getListData = (value) => {
    let listData = getCalendarList(value);

    return listData || [];
  };

  const dateCellRender = (value) => {
    const listData = getListData(value);
    return (
      <ul className="events">
        {listData.map((item) => (
          <li className="events-text" key={item.content}>
            <Badge status={item.type} text={item.content} />
          </li>
        ))}
      </ul>
    );
  };

  const getMonthData = (value) => {
    if (value.month() === 8) {
      return 1394;
    }
  };

  const monthCellRender = (value) => {
    const num = getMonthData(value);
    return num ? (
      <div className="notes-month">
        <section>{num}</section>
        <span>Backlog number</span>
      </div>
    ) : null;
  };

  const onSelect = (value) => {
    setValue(value);
    setSelectedValue(value);
    let past = getContent(pastTrips, value);
    let upcoming = getContent(upcomingTrips, value);
    let ongoing = getContent(ongoingTrips, value);
    setContents(past.concat(upcoming).concat(ongoing));
    showModal();
    return;
  };

  const onPanelChange = (value, mode) => {
    // setValue(value);
    // console.log(value.format('YYYY-MM-DD'), mode);
    // setIsModalVisible(false);
  };

  return (
    <>
      <h2 className="header">Calendar</h2>
      {loading ? (
        <div className="center">
          <Spin size="large" />
        </div>
      ) : (
        <>
          <div>
            <h2 className="header" style={{ fontSize: "20px" }}>
              {moment(selectedValue).format("MMMM")}
            </h2>
          </div>
          <Calendar
            value={value}
            onSelect={(value) => {
              onSelect(value);
            }}
            onPanelChange={onPanelChange}
            dateCellRender={dateCellRender}
            monthCellRender={monthCellRender}
          />{" "}
        </>
      )}
      <Modal
        title={value.format("MMMM Do YYYY")}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        bodyStyle={{ height: "270px", overflow: "scroll" }}
      >
        {contents.length > 0 ? (
          contents.map((content) => (
            <Link to={`/app/trip-summary/${content._id}`} key={content._id}>
              <div className="calendar-item">
                <p style={{ fontSize: "12px" }}>
                  <span style={{ fontWeight: "bold" }}>Start Address:</span> {content.start_address}
                </p>
                <p style={{ fontSize: "12px" }}>
                  <span style={{ fontWeight: "bold" }}>End Address:</span> {content.end_address}
                </p>
                <p style={{ fontSize: "10px" }}>
                  <span style={{ fontWeight: "bold" }}>Status:</span> {content.state.value}
                </p>

                {/*<Divider />*/}
              </div>
            </Link>
          ))
        ) : (
          <p>No Activity(s)</p>
        )}
      </Modal>
    </>
  );
};

const mapStateToProps = ({ user, upcoming_trips }) => ({ user, upcoming_trips });

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(CalendarScreen);
