import React from "react";
import styled from "styled-components";

const SecondaryCard = ({ title, info, children }) => {
  return (
    <CardContainer>
      <Head>
        {children}
        <h2>{title}</h2>
      </Head>
      <p>{info}</p>
    </CardContainer>
  );
};

const CardContainer = styled.div`
  p {
    margin-left: 25px;
  }
`;

const Head = styled.div`
  display: flex;
  align-items: center;

  h2 {
    margin: 0;
  }
`;

export default SecondaryCard;
