import React, { useState, useEffect } from "react";
import queryString from "query-string";
import ResetPasswordForm from "../components/ResetPasswordForm";
import AuthLayout from "../components/layout-components/AuthLayout";

const ResetPassword = ({ location }) => {
  const [token, setToken] = useState();

  useEffect(() => {
    const result = queryString.parse(location.search);
    setToken(result.token);
  }, []);

  return (
    <AuthLayout>
      <ResetPasswordForm token={token} />
    </AuthLayout>
  );
};

export default ResetPassword;
