import moment from "moment";
let _ = require("underscore");

export let trip;
export const setTrip = (data) => {
  trip = data;
};

export function objectToFormData(object) {
  const formData = new FormData();
  Object.keys(object).forEach((key) => formData.append(key, object[key]));
  return formData;
}

// export async function uploadImageToS3(uri,signedUrl){
//
//     if(signedUrl){
//         try{
//             const imgResponse = await fetch(uri);
//             const blob = await imgResponse.blob();
//             console.log("in here");
//             const response = await fetch(signedUrl, {
//                 method: 'PUT',
//                 body: blob
//             })
//             console.log(uri, 'uri');
//             console.log(signedUrl, 'signedUrl');
//             if(response.status === 200){
//                 return true;
//             }
//             else {return response}
//         } catch (e) {
//             return false;
//         }
//     }
//     return false
// }

export function getContent(trips, date) {
  if (trips.length === 0) {
    return [];
  }

  let result = _.filter(trips, function (trip) {
    return moment(date).format("MMMM DD YYYY") === moment(trip.start_time).format("MMMM DD YYYY");
  });

  return result;
}

export function formatTripsForCalendar(trips, date, tag) {
  if (trips.length === 0) {
    return [];
  }
  let result = _.filter(trips, function (trip) {
    return moment(date).format("MMMM Do YYYY") === moment(trip.start_time).format("MMMM Do YYYY");
  });

  let final = _.map(result, function (num, key) {
    return {
      date: num.start_time,
      type: tag,
      content: num.end_address,
    };
  });

  if (tag === "warning" && trips.length !== 0 && result.length !== 0) {
    // console.log(moment(date).format('MMMM Do YYYY'));
  }
  return final;
}

export function getKeyByValue(object, value) {
  for (const key in object) {
    if (object[key] === value) {
      return key;
    }
  }
  return null;
}

export const formatMoney = ({ value, currency = "NGN", locale = "en-NG", money }) => {
  value = Number(value);
  const formatter = new Intl.NumberFormat(locale, money ? { style: "currency", currency } : "");
  return formatter.format(value);
};
