import React, { useState } from "react";
import { Button, Form, Input, Spin, Upload, Checkbox } from "antd";
import { reduxFunctions } from "../helper/ReduxHelper";
import loadingIcon from "./common/loadingIcon";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import "../styles/auth.css";
import { toast } from "react-toastify";
import { DOCUMENT_TYPES } from "../constants/Enums";
import styled from "styled-components";

const { Dragger } = Upload;

const RegisterCompanyForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    family_name: "",
    given_name: "",
    phone_number: "",
    email: "",
    password: "",
    confirm_password: "",
    user_type: "company",
    name: "",
    business_number: "",
  });
  const [consent, setConsent] = useState(false);
  const [staffData, setStaffData] = useState({
    document_type: DOCUMENT_TYPES.STAFF_ID,
    document_id: "",
    document_image: "",
  });
  const [uploadUrl, setUploadUrl] = useState(null);
  // eslint-disable-next-line
  const [imageUrl, setImageUrl] = useState(null);
  const [s3ImageUrl, setS3ImageUrl] = useState(null);
  // eslint-disable-next-line
  const [fileList, setFileList] = useState([]);

  const _startLoading = () => {
    setLoading(true);
  };

  const _stopLoading = () => {
    setLoading(false);
  };

  const beforeUpload = async (file) => {
    const fileType = file.type === "image/jpeg" || file.type === "image/png" || file.type === "application/pdf";
    if (!fileType) {
      toast.error("You can only upload an acceptable file");
      return;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      toast.error("Image should not exceed 2MB");
      return;
    }
    await props
      .getUploadUrl(file.type)
      .then(async (res) => {
        let url = res.payload.data.upload.url;
        let path = res.payload.data.upload.path;
        setUploadUrl(url);
        setS3ImageUrl(path);
        return fileType && isLt2M;
      })
      .catch((e) => {
        toast.error("Image should not exceed 2MB");
        return;
      });
  };

  const handleFileChange = (info) => {
    setImageUrl(null);
    setFileList([]);
    // data.profile_picture = null;
    let file = info.file;
    if (file.status === "removed") {
      setImageUrl(null);
      setFileList([]);
    } else if (file.status === "uploading") {
      setFileList([file]);
      // setLoadingImage(true);
    } else if (file.status === "done") {
      let url = s3ImageUrl;
      setFileList([{ uid: "current", name: file.name, status: "done", url: url }]);
      setImageUrl(url);
      // setLoadingImage(false);
    }
  };

  const handleUpload = async ({ file, onSuccess, onError, onProgress }) => {
    const xhr = new XMLHttpRequest();
    // S3 requires PUT method!
    xhr.open("PUT", uploadUrl);
    xhr.onreadystatechange = async () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          // Calls the update prop
          setStaffData({ ...staffData, document_image: s3ImageUrl });
          setImageUrl(s3ImageUrl);
          onSuccess(null, file);
        } else {
          toast.error("Upload Failed");
        }
      }
    };
    xhr.upload.onprogress = (e) => {
      if (e.lengthComputable) {
        onProgress({ percent: Math.round((e.loaded / e.total) * 100).toFixed(2) }, file);
      }
    };
    xhr.send(file);
  };

  const draggerProps = {
    name: "file",
    beforeUpload: beforeUpload,
    multiple: true,
    action: uploadUrl,
    onChange: handleFileChange,
    customRequest: (options) => handleUpload(options),
    // fileList: {fileList}
  };

  const _signUpUser = () => {
    const payload = { ...data, documents: [staffData] };

    if (data.password !== data.confirm_password) return toast.error("Passwords do not match");

    if (!consent) return toast.error("Please read and agree to our Terms of Use and Privacy Policy");

    if (data.password === data.confirm_password) {
      _startLoading();
      props
        .signUp(payload)
        .then((res) => {
          props
            .createCompany(payload)
            .then((res) => {
              _stopLoading();
              toast.success("Created company successfully!");
              props.handleRedirect();
            })
            .catch((error) => {
              toast.error("Error creating company!");
              localStorage.removeItem("access_token");
              _stopLoading();
              return error;
            });
        })
        .catch((error) => {
          // toast.error('Error creating user')
          toast.error(error.error?.response?.data?.message);
          _stopLoading();
          return error;
        });
    }
  };

  const handleChange = ({ currentTarget: input }) => {
    const newData = { ...data };
    newData[input.name] = input.value;
    setData(newData);
  };

  return (
    <Container>
      <div className="inner">
        <div style={{ marginBottom: "20px" }}>
          <h2>Sign Up as a Company</h2>
          <p className="sign-in">
            Already have an account? <Link to="/login"> Sign In</Link>
          </p>
        </div>

        <Form layout="vertical" name="login-form" onFinish={_signUpUser}>
          <Form.Item label="First Name" className="label">
            <Input className="input" name="family_name" value={data.family_name} onChange={handleChange} />
          </Form.Item>
          <Form.Item label="Last Name" className="label">
            <Input className="input" name="given_name" value={data.given_name} onChange={handleChange} />
          </Form.Item>
          <Form.Item label="Phone Number" className="label">
            <Input className="input" name="phone_number" value={data.phone_number} onChange={handleChange} />
          </Form.Item>
          <Form.Item
            label="Email Address"
            rules={[
              { message: "Please input your email" },
              {
                type: "email",
                message: "Please enter a valid email!",
              },
            ]}
            className="label"
          >
            <Input className="input" name="email" value={data.email} onChange={handleChange} />
          </Form.Item>

          <Form.Item label="Company Name" className="label">
            <Input className="input" name="name" value={data.name} onChange={handleChange} />
          </Form.Item>
          <Form.Item label="Company Phone Number" className="label">
            <Input className="input" name="business_number" value={data.business_number} onChange={handleChange} />
          </Form.Item>
          <Form.Item label="Staff ID" className="label">
            <Input
              className="input"
              name="document_id"
              value={staffData.document_id}
              onChange={(e) => setStaffData({ ...staffData, document_id: e.target.value })}
            />
          </Form.Item>
          <Form.Item label="Upload Staff ID" className="label">
            <Dragger {...draggerProps}>
              <p style={{ fontWeight: "normal" }}>
                <span style={{ color: "#e27626", fontSize: "14px" }}>Click or upload</span> or drag and drop
              </p>
              <p className="ant-upload-hint" style={{ fontSize: "12px", fontWeight: "normal" }}>
                PNG, JPG or PDF(max 2MB)
              </p>
            </Dragger>
          </Form.Item>

          <Form.Item label="Password" className="label">
            <Input.Password className="input" name="password" value={data.password} onChange={handleChange} />
          </Form.Item>

          <Form.Item label="Confirm Password" className="label">
            <Input.Password
              className="input"
              name="confirm_password"
              value={data.confirm_password}
              onChange={handleChange}
            />
          </Form.Item>

          <div className="check">
            <Checkbox checked={consent} onChange={() => setConsent(!consent)} />
            <p className="p2">
              By creating an account, I acknowledge that I have read, understood, and agree to the{" "}
              <a href="/terms" target="_blank" rel="noreferrer">
                Terms of Service
              </a>{" "}
              and{" "}
              <a href="/policy" target="_blank" rel="noreferrer">
                Privacy Policy
              </a>
              .
            </p>
          </div>

          <Form.Item>
            <Button type="primary" className="btn" htmlType="submit" block>
              {loading ? <Spin indicator={loadingIcon} /> : <span>Create Account</span>}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Container>
  );
};

const Container = styled.div`
  a,
  p {
    font-size: 14px;
  }

  .input {
    padding: 8px 12px;
    background: #fff;
    border-radius: 8px;
  }

  .check {
    display: flex;
    gap: 10px;
  }

  .btn {
    margin-top: 20px;
    padding: 12px;
    height: 3rem;
    border-radius: 8px;
  }
`;

const mapStateToProps = ({ user }) => ({ user });

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(RegisterCompanyForm);
