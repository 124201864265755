import React from "react";
import styled from "styled-components";

const EmptyState = () => {
  return (
    <Container>
      <div className="content-container">
        <img src="/empty-transaction.svg" alt="Empty Transactions" />
        <h2>No Transactions Yet</h2>
        <p>
          Recent transactions will appear here. Enter the amount you want to top up and click the 'Fund Wallet' button
          to proceed with your first top-up.
        </p>
      </div>
    </Container>
  );
};

export default EmptyState;

const Container = styled.div`
  min-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  .content-container {
    max-width: 600px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  img {
    max-height: 120px;
  }

  h2 {
    margin: 10px 0 0px 0;
    padding: 0;
  }

  p {
    margin: 0;
  }
`;
