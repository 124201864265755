import React from 'react';
import { Column } from '@ant-design/charts'


const CustomColumn = (props) => {

    const data = [
        {
            date: 'July 10',
            amount: 80000,
        },
        {
            date: 'July 17',
            amount: 90000,
        },
        {
            date: 'July 24',
            amount: 50000,
        },
        {
            date: 'August 1',
            amount: 85000,
        },
        {
            date: 'August 8',
            amount: 70000,
        },
        {
            date: 'August 15',
            amount: 60000,
        },
        {
            date: 'August 27',
            amount: 45000,
        },
        {
            date: 'September 3',
            amount: 120000,
        },
    ];

    const config = {
        data: props.data ? props.data : data,
        xField: 'date',
        yField: 'amount',
        color: '#E27626'
        // label: {
        //     position: 'middle',
        //     style: {
        //         fill: '#FFFFFF',
        //         opacity: 0.6,
        //     },
        // },
        // meta: {
        //     type: { alias: '类别' },
        //     sales: { alias: '销售额' },
        // },
    };

    return (<Column style={{minHeight: '300px'}}
                    {...config}
        // onReady={(plot) => {
        //     plot.on('plot:click', (evt) => {
        //         const { x, y } = evt;
        //         const { xField } = plot.options;
        //         const tooltipData = plot.chart.getTooltipItems({ x, y });
        //         console.log(tooltipData);
        //     });
        // }}
    />);
}


export default CustomColumn;