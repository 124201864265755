export const agreementData = [
  {
    title: "Introduction",
    content: [
      "This Service Level Agreement (SLA) outlines the commitments made by Go Truck Nigerian LTD, regarding the delivery of our service for connecting people with goods to trucking companies.",
    ],
  },
  {
    title: "Service Availability",
    content: [
      "We guarantee a minimum uptime of 99.5% for our platform. In the event that the platform is unavailable, we will make every effort to restore service as soon as possible.",
    ],
  },
  {
    title: "Response Time",
    content: [
      "Our support team will respond to all support inquiries from trucking companies within 8 hours. In the event of a critical issue, our team will respond within 2 hours.",
    ],
  },
  {
    title: "Pick-Up and Delivery",
    content: [
      "Trucking companies are expected to arrive at the pickup location within the estimated time provided and make delivery within the estimated delivery time, subject to circumstances outside of their control such as weather conditions and road closures.",
    ],
  },
  {
    title: "Payment",
    content: [
      "Payments for services rendered will be made to trucking companies within 14 business days of successful delivery.",
    ],
  },
  {
    title: "Liability",
    content: [
      "Trucking companies shall be solely responsible for the goods being transported and for any damages that may occur during transport. Go Truck Nigerian LTD shall not be liable for any damages arising from the transport of goods",
    ],
  },
  {
    title: "Changes to the SLA",
    content: ["We reserve the right to modify this SLA at any time without prior notice."],
  },
  {
    title: "Dispute Resolution",
    content: [
      "Any disputes arising from this SLA will be resolved through arbitration in accordance with the rules of the American Arbitration Association.",
    ],
  },
];

export const policyData = [
  {
    title: "Information We Collect",
    content: [
      "We collect information that you provide to us when you use the Service, such as your name, email address, phone number, location information, and payment information. We may also collect information about your use of the Service, including your IP address, browser type, and operating system.",
    ],
  },
  {
    title: "Use of Information",
    content: [
      "We use the information we collect to operate and improve the Service, to communicate with you, and to provide customer support. We may also use the information to personalize the Service for you and to send you marketing communications. We will not share your information with third parties for their marketing purposes without your consent.",
    ],
  },
  {
    title: "Disclosure of Information",
    content: [
      "We may disclose your information to third-party service providers who assist us in operating the Service, such as trucking companies and payment processors. We may also disclose your information if we are required to do so by law or if we believe that such disclosure is necessary to protect our rights or the rights of others.",
    ],
  },
  {
    title: "Cookies and Tracking",
    content: [
      "We may use cookies and other tracking technologies to collect information about your use of the Service. This information may include your browser type, operating system, and pages visited on the Service. We use this information to improve the Service and to deliver personalized content and advertising.",
    ],
  },
  {
    title: "Security",
    content: [
      "We take reasonable measures to protect the information that we collect from unauthorized access, disclosure, or misuse. However, no security system is perfect, and we cannot guarantee that your information will be completely secure.",
    ],
  },
  {
    title: "Data Retention",
    content: [
      "We will retain your information for as long as necessary to fulfill the purposes for which it was collected, unless a longer retention period is required or permitted by law.",
    ],
  },
  {
    title: "Payment Information Collection",
    content: [
      "When you make a payment through the Service, Paystack will collect your payment information, such as your credit card number or bank account information. Paystack will also collect your name, email address, and other information that we need to process your payment.",
    ],
  },
  {
    title: "Payment Information Security",
    content: [
      "Paystack uses industry-standard security measures to protect your payment information, including encryption, firewalls, and secure servers. Paystack's privacy policy governs the collection, use, and disclosure of your payment information. You can read Paystack's privacy policy at https://paystack.com/terms.",
    ],
  },
  {
    title: "Payment Information Disclosure",
    content: [
      "We do not store your payment information on our servers. However, we may share your payment information with Paystack as necessary to process your payment. Paystack may also disclose your payment information as required by law or as necessary to protect their rights or the rights of others.",
    ],
  },
  {
    title: "Payment Renewal and Cancellation",
    content: [
      "If you sign up for a subscription plan, your payment information will be stored by Paystack to process recurring payments. You can cancel your subscription at any time by logging into your account on the Service and following the cancellation instructions.",
    ],
  },
  {
    title: "Contact Information",
    content: [
      "If you have any questions or concerns about the collection, use, or disclosure of your payment information, please contact us at support@gotruck.io. You can also contact Paystack directly at https://paystack.com/contact/support.",
    ],
  },
  {
    title: "Your Rights",
    content: [
      "You may have certain rights with respect to the information we collect about you, such as the right to access, correct, or delete your information. Please contact us using the information provided at the end of this privacy policy to exercise these rights.",
    ],
  },
  {
    title: "Children's Privacy",
    content: [
      "The Service is not intended for children under the age of 13, and we do not knowingly collect information from children under the age of 13.",
    ],
  },
  {
    title: "Changes to the Privacy Policy",
    content: [
      "We may modify this privacy policy at any time. If we make material changes to this privacy policy, we will notify you by email or by posting a notice on the Service.",
    ],
  },
];
