import React from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import Preview from "../../assets/preview.png";

const AuthLayout = ({ children }) => {
  const pathname = useLocation().pathname;
  const isDriverPath = pathname === "/register_driver" ? true : false;

  return (
    <Container>
      <nav className="nav">
        <img src="/gotruck-logo.svg" alt="GoTruck" />

        <div className="driver-cta">
          <p>{isDriverPath ? "Do you own a company?" : "Do you own a vehicle?"}</p>
          <Link to={isDriverPath ? "register_company" : "/register_driver"}>
            Sign Up as a {isDriverPath ? "Company" : "Driver"}
          </Link>
        </div>
      </nav>

      <div className="col-1">
        <div>
          <img src={Preview} alt="Preview" />
          <h1>Haul with Go Truck</h1>
          <h3>
            With upfront pricing, instant booking, free quick pay and facility ratings, Go Truck puts you in the
            driver's seat.
          </h3>
        </div>
      </div>

      <div className="col-2">
        <div className="form-container">{children}</div>
      </div>
    </Container>
  );
};

export default AuthLayout;

const Container = styled.div`
  display: flex;
  background-color: #f4f7f7;
  height: 100vh;
  position: relative;
  padding: 88px 50px 0px 50px;

  .nav {
    width: 100%;
    padding: 20px 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;

    .driver-cta {
      display: flex;
      align-items: center;
      gap: 12px;

      a {
        background: #fff;
        border: 1px solid #e27626;
        padding: 10px 16px;
        font-size: 14px;
        border-radius: 10px;
      }
    }

    @media (max-width: 800px) {
      padding: 30px 10px 10px 10px;
    }

    @media (max-width: 600px) {
      .driver-cta {
        flex-direction: column;
        gap: 4px;
      }
    }
  }

  .col-1 {
    padding: 20px;
    width: 55%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    text-align: center;

    img {
      max-width: 550px;
      margin: auto;
    }

    h3 {
      max-width: 400px;
      margin: auto;
      font-size: 14px;
      color: #47464a;
    }

    @media (max-width: 1110px) {
      display: none;
    }
  }

  .col-2 {
    padding: 20px;
    width: 45%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .form-container {
      max-height: 95%;
      overflow: auto;
      padding: 35px 35px 15px 35px;
      border-radius: 12px;
      max-width: 500px;
      width: 100%;
      background: #fbfcfc;
      filter: drop-shadow(0px 8px 32px rgba(0, 0, 0, 0.06));
    }

    @media (max-width: 1110px) {
      width: 100%;
      padding: 80px 10px;

      .form-container {
        max-height: auto%;
      }
    }
  }

  @media (max-width: 1110px) {
    display: flex;
    flex-direction: column;
    padding: 40px 10px 10px 10px;
    height: auto;
  }
`;
