import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { reduxFunctions } from "../helper/ReduxHelper";
import TripContent from "../components/TripContent";
import { Divider, Spin } from "antd";
import moment from "moment";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { getKeyByValue } from "../helper/UtilityHelper";
import { VEHICLE_TYPES } from "../constants/Enums";

const TripSummary = (props) => {
  const [data, setData] = useState(props.trip);
  const [loading, setLoading] = useState(false);
  const [driver, setDriver] = useState({});
  const [driverLoading, setDriverLoading] = useState(false);
  const [vehicle, setVehicle] = useState({});
  const [vehicleLoading, setVehicleLoading] = useState(false);

  const getTrip = () => {
    setLoading(true);
    props
      .getTrip(props.match.params.id)
      .then(async (res) => {
        setData(res.payload.data.trip);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        // toast.error('Error getting trip data!');
        return error;
      });
  };

  const getDriver = (id) => {
    setDriverLoading(true);
    props
      .getUserWithId(id)
      .then((res) => {
        setDriver(res.payload.data.user);
        setDriverLoading(false);
        return true;
      })
      .catch((error) => {
        setDriverLoading(false);
        return error;
      });
  };

  const _getVehicle = (id) => {
    setVehicleLoading(true);
    props
      .getVehicle(id)
      .then((res) => {
        setVehicleLoading(false);
        setVehicle(res.payload.data.vehicle);
        return;
      })
      .catch((error) => {
        setVehicleLoading(false);
        return error;
      });
  };

  useEffect(() => {
    if (props.match.params.id) {
      getTrip();
    }
  }, [props.match.params.id]);

  useEffect(() => {
    if (data.driver || data.vehicle) {
      getDriver(data?.driver);
      _getVehicle(data?.vehicle);
    }
  }, [data]);

  return (
    <div>
      <h2 className="header">Trip summary</h2>
      {loading ? (
        <div className="center">
          <Spin size="large" />
        </div>
      ) : data.state ? (
        <div className="trip-details-container">
          <div className="trip-details-head">
            <ArrowLeftOutlined className="icon" onClick={props.history.goBack} />
            <h3>Trip Details</h3>
          </div>
          <Divider />
          <div className="inner">
            <TripContent contentKey={"Status: "} content={data?.state.value} />
            {data?.cargo_category && <TripContent contentKey={"Category: "} content={data?.cargo_category} />}
            {data?.cargo_description && <TripContent contentKey={"Description: "} content={data?.cargo_description} />}
            <TripContent contentKey={"Start Address: "} content={data?.start_address} />
            <TripContent contentKey={"End Address: "} content={data?.end_address} />
            <TripContent contentKey={"Date: "} content={moment(data?.start_time).format("MMM DD YYYY hh:mm A")} />
            <TripContent contentKey={"Distance: "} content={data?.cost_id?.formatted_distance} />
            <TripContent contentKey={"Duration: "} content={data?.cost_id?.formatted_duration} />
            <TripContent contentKey={"Size: "} content={`${getKeyByValue(VEHICLE_TYPES, data?.item_size)}`} />
            {/*<TripContent contentKey={'Total price: '} content={data.cost_id.formatted_price}/>*/}
            {data?.drop_off_address && (
              <TripContent contentKey={"Dropoff Location: "} content={data?.drop_off_address} />
            )}
            {vehicle?.plate_number && (
              <TripContent
                contentKey={"Plate Number: "}
                content={vehicleLoading ? "N/A" : `${vehicle?.plate_number}`}
              />
            )}
            <TripContent
              contentKey={"Driver: "}
              content={driverLoading ? "N/A" : `${driver?.given_name} ${driver?.family_name}`}
            />
            {data?.cargo_image && <TripContent contentKey={"Cargo Image: "} content="" source={data?.cargo_image} />}
            {data?.customer_comment && <TripContent contentKey={"Comment: "} content={data?.customer_comment} />}
          </div>

          {data?.tdo?.tdo_image ? (
            <>
              <h3 style={{ marginTop: "50px" }}>TDO Details</h3>
              <Divider />
              <div className="inner">
                <TripContent contentKey={"TDO Image: "} content="" source={data?.tdo?.tdo_image} />
                <TripContent contentKey={"TDO Status: "} content={data?.tdo?.status} />
                <TripContent
                  contentKey={"Valid Until: "}
                  content={moment(data?.tdo?.valid_until).format("MMM DD YYYY hh:mm A")}
                />
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      ) : (
        <div className="trip-details-container center">
          <h2>No trip details found</h2>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = ({ user, trip }) => ({ user, trip });

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(TripSummary);
