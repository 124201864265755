import React from "react";
import { Avatar, Image } from "antd";

const TripContent = ({ contentKey, content, source }) => {
  return (
    <div className="trip-content">
      <p className="key">{contentKey}</p>
      {!source ? <p>{content}</p> : <Avatar shape="square" size={"default"} src={<Image src={source} />} />}
    </div>
  );
};

export default TripContent;
