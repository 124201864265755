import React from 'react';
import {Input} from 'antd';
import {SearchOutlined} from "@ant-design/icons";



const CustomInput = ({placeholder, size, className, value, handleChange}) => {
    return <Input placeholder={placeholder} size={size} className={className} prefix={<SearchOutlined />} value={value} onChange={handleChange}/>
}

export default CustomInput;