import React, {useEffect, useState} from 'react';
import { Menu } from 'antd'
import { SidebarTree } from '../../configs/NavConfig';
import { Link } from 'react-router-dom'
import Icon from '../../components/util-components/Icon'
import {
    useLocation
} from "react-router-dom";
import useWindowSize from "../../hooks/useWindowSize";
import '../../styles/main.css'


export const Sidebar = () => {
    const [active, setActive] = useState('/app/settings/change-password')

    let location = useLocation();

    useEffect(() => {
        setActive(location.pathname)
    }, [location.pathname])

    const size = useWindowSize();
    return (
        <div>
            <Menu
                defaultSelectedKeys={[active]} selectedKeys={active}
                mode={size.width > 800 ? "inline" : 'horizontal'}
                inlineCollapsed={false}
                className='settings-sidebar'
            >
                {SidebarTree.map(menu => <Menu.Item key={menu.key} icon={<Icon type={menu.icon}/>}>
                    <Link to={menu.path} className='settings-link'>
                        {menu.title}
                    </Link>
                </Menu.Item> )}
            </Menu>
        </div>
    )
}

export default Sidebar;