import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Col, Row } from "antd";
import Notification from "./Notification";
import Billing from "./Billing";
import ChangePassword from "./ChangePassword";
import Sidebar from "./Sidebar";
import useWindowSize from "../../hooks/useWindowSize";

const Settings = (props) => {
  const size = useWindowSize();
  return (
    <Row className="billing">
      <Col span={size.width > 800 ? 6 : 24}>
        <Sidebar />
      </Col>
      <Col span={size.width > 800 ? 18 : 24} className="col2">
        <Switch>
          <Route path="/app/settings/notification" component={Notification} />
          <Route path="/app/settings/billing" component={Billing} />
          <Route path="/app/settings/change-password" component={ChangePassword} />
          <Redirect from="/app/settings" to="/app/settings/change-password" />
        </Switch>
      </Col>
    </Row>
  );
};

export default Settings;
