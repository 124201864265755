import React, { useEffect, useState } from "react";
import { Button, Form, Input, Upload, Spin, DatePicker } from "antd";
import "../styles/main.css";
import { reduxFunctions } from "../helper/ReduxHelper";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import loadingIcon from "../components/common/loadingIcon";
import ImageUpload from "../components/common/ImageUpload";
import moment from "moment";
import { authenticatedUser } from "../helper/AuthHelper";

const { Dragger } = Upload;

const UserProfile = (props) => {
  const [data, setData] = useState({
    ...props.user,
    drivers_license: { license_number: "", license_image: "", expiry_date: "" },
  });
  const [loadingImage, setLoadingImage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [uploadUrl, setUploadUrl] = useState(null);
  const [s3ImageUrl, sets3ImageUrl] = useState(null);
  // eslint-disable-next-line
  const [licenseImageUrl, setLicenseImageUrl] = useState(null);
  const [uploadLicenseUrl, setLicenseUploadUrl] = useState(null);
  const [s3LicenseImageUrl, sets3LicenseImageUrl] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [defaultFileList, setDefaultFileList] = useState([]);

  const userDetails = authenticatedUser();
  const userType = userDetails?.user_type;

  const handleChange = ({ currentTarget: input }) => {
    let newData = { ...data };
    newData[input.name] = input.value;
    setData(newData);
  };

  const getUser = () => {
    props
      .getMe()
      .then((res) => {
        const newData = { ...res.payload.data.user };
        const newArray = newData.full_name.split(" ");
        if (newArray[0] === "undefined") {
          newData.full_name = newArray[1];
        }
        if (newArray[1] === "undefined") {
          newData.full_name = newArray[0];
        }
        if (newArray[0] !== "undefined" && newArray[1] !== "undefined") {
          newData.full_name = `${newArray[0]} ${newArray[1]}`;
        }
        setData({ ...data, ...newData });
      })
      .catch((error) => {
        // toast.error('Error getting user data!');
        return error;
      });
  };

  const handleSave = async () => {
    setLoading(true);
    const newData = { ...data };
    if (imageUrl) {
      newData.profile_picture = {
        original: imageUrl,
      };
    }
    await props
      .updateUser(newData)
      .then(async (res) => {
        toast.success("Profile updated successfully");
        setLoading(false);
      })
      .catch((error) => {
        toast.error("Error saving user");
        setLoading(false);
        return error;
      });
  };

  const beforeUpload = async (file) => {
    const fileType = file.type === "image/jpeg" || file.type === "image/png" || file.type === "application/pdf";
    if (!fileType) {
      toast.error("You can only upload an acceptable file");
      return;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      toast.error("Image should not exceed 2MB");
      return;
    }
    await props
      .getUploadUrl(file.type)
      .then(async (res) => {
        let url = res.payload.data.upload.url;
        let path = res.payload.data.upload.path;
        setLicenseUploadUrl(url);
        sets3LicenseImageUrl(path);
        return fileType && isLt2M;
      })
      .catch((e) => {
        toast.error("Image should not exceed 2MB");
        return;
      });
  };

  const handleFileChange = (info) => {
    setLicenseImageUrl(null);
    setFileList([]);
    let file = info.file;
    if (file.status === "removed") {
      setLicenseImageUrl(null);
      // setFileList([]);
      setDefaultFileList([]);
    } else if (file.status === "uploading") {
      // setFileList([file]);
      setDefaultFileList([file]);
    } else if (file.status === "done") {
      let url = s3ImageUrl;
      // setFileList([{ uid: "current", name: file.name, status: "done", url: url }]);
      setDefaultFileList([{ uid: "current", name: file.name, status: "done", url: url }]);
      setLicenseImageUrl(url);
    }
  };

  const handleUpload = async ({ file, onSuccess, onError, onProgress }) => {
    const xhr = new XMLHttpRequest();
    xhr.open("PUT", uploadLicenseUrl);
    xhr.onreadystatechange = async () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          setData({ ...data, drivers_license: { ...data.drivers_license, license_image: s3LicenseImageUrl } });
          setLicenseImageUrl(s3LicenseImageUrl);
          onSuccess(null, file);
        } else {
          toast.error("Upload Failed");
        }
      }
    };
    xhr.upload.onprogress = (e) => {
      if (e.lengthComputable) {
        onProgress({ percent: Math.round((e.loaded / e.total) * 100).toFixed(2) }, file);
      }
    };
    xhr.send(file);
  };

  const draggerProps = {
    name: "file",
    beforeUpload: beforeUpload,
    multiple: true,
    action: uploadLicenseUrl,
    onChange: handleFileChange,
    customRequest: (options) => handleUpload(options),
    // defaultFileList: defaultFileList,
    fileList: defaultFileList,
  };

  useEffect(() => {
    if (data.profile_picture) {
      setImageUrl(data.profile_picture.original);
      setFileList([
        {
          uid: "current",
          status: "done",
          url: data.profile_picture.original,
        },
      ]);
    }
  }, []);
  useEffect(getUser, []);
  useEffect(() => {
    if (authenticatedUser()?.drivers_license?.license_image) {
      setLicenseImageUrl(authenticatedUser()?.drivers_license?.license_image);
      setDefaultFileList([
        {
          uid: "current",
          name: "License Image",
          status: "done",
          url: authenticatedUser()?.drivers_license?.license_image, // URL string to be passed to the Dragger component
        },
      ]);
    }
  }, [authenticatedUser]);

  return (
    <div>
      <h2 className="header">Profile</h2>
      <div className="profile-container">
        <div className="image-upload">
          <ImageUpload
            data={data}
            loadingImage={loadingImage}
            uploadUrl={uploadUrl}
            fileList={fileList}
            imageUrl={imageUrl}
            setUploadUrl={setUploadUrl}
            sets3ImageUrl={sets3ImageUrl}
            setImageUrl={setImageUrl}
            setFileList={setFileList}
            setLoadingImage={setLoadingImage}
            s3ImageUrl={s3ImageUrl}
            listType="picture-card"
            button={false}
          />
        </div>
        <Form layout="vertical" name="login-form" onFinish={handleSave} className="profile-form-container">
          <Form.Item label="Last Name" className="label">
            <Input
              placeholder=""
              className="input"
              name="family_name"
              value={data.family_name}
              onChange={handleChange}
              size={"large"}
            />
          </Form.Item>

          <Form.Item label="First Name" className="label">
            <Input
              placeholder=""
              className="input"
              name="given_name"
              value={data.given_name}
              onChange={handleChange}
              size={"large"}
            />
          </Form.Item>

          {userType !== "driver" && (
            <Form.Item label="Phone Number" className="label">
              <Input
                placeholder=""
                className="input"
                name="phone_number"
                value={data.phone_number}
                onChange={handleChange}
                size={"large"}
              />
            </Form.Item>
          )}
          <Form.Item label="Home Address" className="label">
            <Input
              placeholder=""
              className="input"
              name="home_address"
              value={data.home_address}
              onChange={handleChange}
              size={"large"}
            />
          </Form.Item>

          {userType === "driver" && (
            <>
              <Form.Item label="License Number" className="label">
                <Input
                  className="input"
                  name="license_number"
                  value={data.drivers_license.license_number}
                  onChange={(e) =>
                    setData({ ...data, drivers_license: { ...data.drivers_license, license_number: e.target.value } })
                  }
                />
              </Form.Item>

              <Form.Item label="Upload License ID" className="label">
                <Dragger {...draggerProps}>
                  <p style={{ fontWeight: "normal" }}>
                    <span style={{ color: "#e27626", fontSize: "14px" }}>Click or upload</span> or drag and drop
                  </p>
                  <p className="ant-upload-hint" style={{ fontSize: "12px", fontWeight: "normal" }}>
                    PNG, JPG or PDF(max 2MB)
                  </p>
                </Dragger>
              </Form.Item>

              <Form.Item label="License Expiry Date" className="label">
                <DatePicker
                  picker="date"
                  className="input"
                  value={moment(data?.drivers_license?.expiry_date || new Date(), "YYYY-MM-DD")}
                  style={{ width: "100%" }}
                  onChange={(date, dateString) =>
                    setData({
                      ...data,
                      drivers_license: { ...data.drivers_license, expiry_date: dateString },
                    })
                  }
                />
              </Form.Item>
            </>
          )}

          <Form.Item>
            <Button htmlType="submit" block className="all-button" type="primary" size={"large"}>
              {loading ? <Spin indicator={loadingIcon} /> : <span>Save</span>}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
const mapStateToProps = ({ user, imageToken }) => ({ user, imageToken });

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
