import React from "react";
import styled from "styled-components";
import { policyData } from "../helper/slaHelper";
// import Footer from "../../components/layout/Footer";

const PrivacyPolicy = () => {
  return (
    <>
      <Container>
        <Head>
          <h1>Privacy Policies</h1>
          <p className="text-[#667085] text-base">
            Go Truck Nigeria Ltd is committed to protecting your privacy. This privacy policy describes how we collect,
            use, and disclose information that we obtain through our application (the "Service"). By using the Service,
            you consent to the terms of this privacy policy.
          </p>
          <p>Last updated: April 11th, 2023</p>
        </Head>
        <Content>
          {policyData?.map(({ title, content }, i) => (
            <div className={"detail"} key={title + i}>
              <p className={"left"}>
                {i + 1}. {title}
              </p>
              <div className={"right"}>
                {content?.map((text, i) => (
                  <p key={i}>{text}</p>
                ))}
              </div>
            </div>
          ))}
          <div className={"detail"}>
            <p className={"left"}>{policyData?.length + 1}. Contact Information</p>
            <div className={"right"}>
              <p>
                If you have any questions or concerns regarding this SLA, please contact us at support@gotruck.io or
                write us at
              </p>

              <div className="contact">
                <span>Plot 1 Towry Close</span>
                <span>Off Idejo Street</span>
                <span>Off Adeola Odeku Street</span>
                <span>Victoria Island, Lagos</span>
                <span>Nigeria</span>
                <span>admin@gotruck.io</span>
              </div>

              {/* <p>
                This SLA represents a good faith effort by GoTruck Nigeria LTD to provide a high level of service to our
                trucking company partners.
              </p> */}
            </div>
          </div>
        </Content>
      </Container>
      {/* <Footer width={"90%"} /> */}
    </>
  );
};

const Container = styled.div`
  width: 85%;
  min-height: 50vh;
  margin: 0 auto;
  padding: 40px 0;
`;

const Head = styled.div`
  margin-bottom: 40px;
  h1 {
    color: #192d46;
    font-weight: bold;
    font-size: 40px;
    margin: 0;
  }
`;

const Content = styled.div`
  p,
  ul {
    margin: 0;
    font-size: 17px;
    color: #667085;
  }

  .detail {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .left {
      width: 20%;
      font-size: 20px;
      font-weight: bold;
      color: #192d46;
    }
    .right {
      width: 75%;

      p {
        margin-bottom: 20px;
      }
    }

    .sub-title {
      font-weight: 600;
      color: #192d46;
    }
  }

  .contact {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    color: #667085;
  }

  @media (max-width: 900px) {
    .detail {
      display: block;

      .left {
        width: 100%;
      }
      .right {
        width: 100%;
      }
    }
  }
`;

export default PrivacyPolicy;
