import React, { useEffect } from "react";
import Empty from "../assets/svgs/empty.svg";
import TripItem from "../components/TripItem";
import { connect } from "react-redux";
import { reduxFunctions } from "../helper/ReduxHelper";
import "../styles/main.css";
import { Spin } from "antd";
import { toast } from "react-toastify";

const UpcomingTrips = (props) => {
  const getUpcomingTrips = async () => {
    await props
      .getUpcomingTrips()
      .then((res) => {})
      .catch((error) => {
        toast.error("Error getting upcoming trips data!");
        return error;
      });
  };

  useEffect(() => {
    getUpcomingTrips();
  }, []);

  return (
    <div style={{ minHeight: "80vh" }}>
      <h2 className="header">Upcoming Trips</h2>
      {props.loadingTrip ? (
        <div className="center">
          <Spin size="large" />
        </div>
      ) : props.upcoming_trips?.length > 0 ? (
        props.upcoming_trips?.map((trip) => <TripItem data={trip} key={trip._id} />)
      ) : (
        <div
          style={{
            background: "white",
            height: "75vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={Empty} alt="Empty list" style={{ width: "100px" }} />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = ({ user, upcoming_trips, loadingTrip }) => ({ user, upcoming_trips, loadingTrip });

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(UpcomingTrips);
