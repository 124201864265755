import { createNamedReducer } from "redux-named-reducers";
import { STATUS } from "../../constants/Enums";
import {
  GET_TEST,
  GET_TEST_SUCCESS,
  GET_TEST_FAIL,
  SET_DRIVER_LOCATION,
  SET_CURRENT_TRIP,
  SET_TRIP_DETAIL,
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAIL,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  LOGOUT_USER_FAIL,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  SIGNUP_USER,
  SIGNUP_USER_SUCCESS,
  SIGNUP_USER_FAIL,
  GET_USER,
  GET_USER_SUCCESS,
  GET_USER_FAIL,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  GET_USER_COMPANY,
  GET_USER_COMPANY_SUCCESS,
  GET_USER_COMPANY_FAIL,
  PASSWORD_RESET_USER,
  PASSWORD_RESET_USER_SUCCESS,
  PASSWORD_RESET_USER_FAIL,
  PING_TRIP,
  PING_TRIP_SUCCESS,
  PING_TRIP_FAIL,
  GET_TRIP,
  GET_TRIP_SUCCESS,
  GET_TRIP_FAIL,
  GET_PAST_TRIPS,
  GET_PAST_TRIPS_SUCCESS,
  GET_PAST_TRIPS_FAIL,
  GET_UPCOMING_TRIPS,
  GET_UPCOMING_TRIPS_SUCCESS,
  GET_UPCOMING_TRIPS_FAIL,
  GET_CURRENTTRIP,
  GET_CURRENTTRIP_SUCCESS,
  GET_CURRENTTRIP_FAIL,
  GET_TRIP_TOKEN,
  GET_TRIP_TOKEN_SUCCESS,
  GET_TRIP_TOKEN_FAIL,
  REPORT_TRIP,
  REPORT_TRIP_SUCCESS,
  REPORT_TRIP_FAIL,
  REQUEST_TRIP,
  REQUEST_TRIP_SUCCESS,
  REQUEST_TRIP_FAIL,
  CANCEL_TRIP,
  CANCEL_TRIP_SUCCESS,
  CANCEL_TRIP_FAIL,
  CANCEL_CURRENT_TRIP,
  CANCEL_CURRENT_TRIP_SUCCESS,
  CANCEL_CURRENT_TRIP_FAIL,
  GET_ALL_TRIP,
  GET_ALL_TRIP_SUCCESS,
  GET_ALL_TRIP_FAIL,
  GET_ONGOING_TRIP,
  GET_ONGOING_TRIP_SUCCESS,
  GET_ONGOING_TRIP_FAIL,
  GET_ACCEPTEDTRIP,
  GET_ACCEPTEDTRIP_SUCCESS,
  GET_ACCEPTEDTRIP_FAIL,
  RATE_DRIVER,
  RATE_DRIVER_SUCCESS,
  RATE_DRIVER_FAIL,
  GET_RATING,
  GET_RATING_SUCCESS,
  GET_RATING_FAIL,
  CREATE_COST,
  CREATE_COST_SUCCESS,
  CREATE_COST_FAIL,
  PUSH_NOTIFICATION_TOKEN_REGISTRATION,
  PUSH_NOTIFICATION_TOKEN_REGISTRATION_SUCCESS,
  PUSH_NOTIFICATION_TOKEN_REGISTRATION_FAIL,
  SET_MY_LOCATION,
  REQUEST_NOW,
  GET_UPLOAD,
  GET_UPLOAD_FAIL,
  GET_UPLOAD_SUCCESS,
  CREATE_VEHICLE,
  CREATE_VEHICLE_SUCCESS,
  CREATE_VEHICLE_FAIL,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL,
  CREATE_COMPANY,
  CREATE_COMPANY_SUCCESS,
  CREATE_COMPANY_FAIL,
  GET_CURRENT_COMPANY,
  GET_CURRENT_COMPANY_SUCCESS,
  GET_CURRENT_COMPANY_FAIL,
  GET_VEHICLES,
  GET_VEHICLES_SUCCESS,
  GET_VEHICLES_FAIL,
  UPDATE_COMPANY,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_COMPANY_FAIL,
  ADD_DRIVER_TO_COMPANY,
  ADD_DRIVER_TO_COMPANY_SUCCESS,
  ADD_DRIVER_TO_COMPANY_FAIL,
  GET_COMPANY_DRIVERS,
  GET_COMPANY_DRIVERS_SUCCESS,
  GET_COMPANY_DRIVERS_FAIL,
  GET_COMPANY_AVAILABLE_DRIVERS_BY_SIZE,
  GET_COMPANY_AVAILABLE_DRIVERS_BY_SIZE_SUCCESS,
  GET_COMPANY_AVAILABLE_DRIVERS_BY_SIZE_FAIL,
  PASSWORD_RESET_EMAIL,
  PASSWORD_RESET_EMAIL_SUCCESS,
  PASSWORD_RESET_EMAIL_FAIL,
  GET_VEHICLE,
  GET_VEHICLE_SUCCESS,
  GET_VEHICLE_FAIL,
  UPDATE_VEHICLE,
  UPDATE_VEHICLE_SUCCESS,
  UPDATE_VEHICLE_FAIL,
  ACCEPT_INVITATION,
  ACCEPT_INVITATION_SUCCESS,
  ACCEPT_INVITATION_FAIL,
  GET_USER_WITH_ID,
  GET_USER_WITH_ID_SUCCESS,
  GET_USER_WITH_ID_FAILURE,
  GET_COMPANY_DRIVER_TRIPS,
  GET_COMPANY_DRIVER_TRIPS_SUCCESS,
  GET_COMPANY_DRIVER_TRIPS_FAIL,
  GET_AVAILABLE_TRIPS,
  GET_AVAILABLE_TRIPS_SUCCESS,
  GET_AVAILABLE_TRIPS_FAILURE,
  UPDATE_TRIP_STATUS,
  UPDATE_TRIP_STATUS_SUCCESS,
  UPDATE_TRIP_STATUS_FAILURE,
  GET_COMPANY_AVAILABLE_DRIVERS,
  GET_COMPANY_AVAILABLE_DRIVERS_SUCCESS,
  GET_COMPANY_AVAILABLE_DRIVERS_FAIL,
  GET_TRACKERS_HEALTH,
  GET_TRACKERS_HEALTH_SUCCESS,
  GET_TRACKERS_HEALTH_FAIL,
  VERIFY_TRACKER,
  VERIFY_TRACKER_SUCCESS,
  VERIFY_TRACKER_FAIL,
  FETCH_TERMINALS,
  FETCH_TERMINALS_SUCCESS,
  FETCH_TERMINALS_FAIL,
  CREATE_TRIP,
  CREATE_TRIP_SUCCESS,
  CREATE_TRIP_FAIL,
  REMOVE_DRIVER,
  REMOVE_DRIVER_SUCCESS,
  REMOVE_DRIVER_FAIL,
  GET_PENDING_DRIVERS,
  GET_PENDING_DRIVERS_SUCCESS,
  GET_PENDING_DRIVERS_FAIL,
  DELETE_VEHICLE,
  DELETE_VEHICLE_SUCCESS,
  DELETE_VEHICLE_FAIL,
} from "../actions/Action";
import axios from "axios";
import { FUND_WALLET, FUND_WALLET_FAIL, FUND_WALLET_SUCCESS } from "../actions/PaymentAction";
const ACCESS_TOKEN = "access_token";

const initialState = {
  current_location: {},
  driverLocation: {},
  user: {},
  trip: {},
  detailed_trip: {},
  past_trips: [],
  upcoming_trips: [],
  companies: [],
  trips: [],
  cost: {},
  token: "",
  isPendingPayment: false,
  trip_token: [],
  past_trip_page: 0,
  upcoming_trips_page: 0,
  request_active: false,
  current_trip: {},
  accepted_trips: [],
  ongoing_trips: [],
  requested_trip: {},
  status: STATUS.AVAILABLE,
  loading: false,
  rating: [],
  imageToken: "",
  vehicles: [],
  vehicle: {},
  passwords: {},
  company: {},
  currentCompany: {},
  drivers: [],
  pendingDrivers: [],
  isInviteAccepted: false,
  userById: {},
  loading_fetch_terminals: false,
  create_trip_loading: false,
  loadingAvailableDriverBySize: false,
  available_drivers_by_size: [],
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_MY_LOCATION:
      return { ...state, current_location: action.payload.data };

    case SET_TRIP_DETAIL:
      return { ...state, detailed_trip: action.payload.data };

    case REQUEST_NOW:
      return {
        ...state,
        status: STATUS.REQUESTING_NOW,
        current_trip: state.requested_trip,
        requested_trip: {},
        request_active: true,
      };

    case SET_CURRENT_TRIP:
      return { ...state, current_trip: action.payload.data };

    //USER

    case RESET_PASSWORD:
      return { ...state };
    case RESET_PASSWORD_SUCCESS:
      return { ...state };
    case RESET_PASSWORD_FAIL:
      return { ...state };

    case PASSWORD_RESET_EMAIL:
      return { ...state };
    case PASSWORD_RESET_EMAIL_SUCCESS:
      return { ...state };
    case PASSWORD_RESET_EMAIL_FAIL:
      return { ...state };

    case RATE_DRIVER:
      return { ...state, loading: true };
    case RATE_DRIVER_SUCCESS:
      return { ...state, loading: false };
    case RATE_DRIVER_FAIL:
      return { ...state, loading: false };

    case GET_RATING:
      return { ...state, loading: true };
    case GET_RATING_SUCCESS:
      return { ...state, loading: false, rating: action.payload.data.ratings };
    case GET_RATING_FAIL:
      return { ...state, loading: false, error: "Error sending RATING info" };

    case LOGIN_USER:
      return { ...state, loadingLogin: true };
    case LOGIN_USER_SUCCESS:
      let token = null;
      token = "Bearer " + action.payload.data.oauth.token;
      const user = action.payload.data.user;
      const company = action.payload.data.user.company;

      localStorage.setItem(ACCESS_TOKEN, token);
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("company", JSON.stringify(company));

      axios.defaults.headers.common["Authorization"] = token;

      return { ...state, loginSuccess: true, loadingLogin: false, user: action.payload.data.user, token: token };
    case LOGIN_USER_FAIL:
      return { ...state, loadingLogin: false, loginSuccess: false, error: "Error Logging IN USER " };

    case LOGOUT_USER:
      return { ...state, loadingLogout: true };
    case LOGOUT_USER_SUCCESS:
      // AsyncStorage.removeItem(ACCESS_TOKEN);
      localStorage.removeItem(ACCESS_TOKEN);
      localStorage.removeItem("user");
      localStorage.removeItem("company");
      axios.defaults.headers.common["Authorization"] = null;
      return { ...state, logoutSuccess: true, loadingLogout: false, token: null };
    case LOGOUT_USER_FAIL:
      return { ...state, loadingLogout: false, logoutSuccess: false, error: "Error Logging Out USER " };

    case SIGNUP_USER:
      return { ...state, loadingSignUp: true };
    case SIGNUP_USER_SUCCESS:
      const token1 = "Bearer " + action.payload.data.oauth.token;
      const userNew = action.payload.data.user;

      localStorage.setItem(ACCESS_TOKEN, token1);
      localStorage.setItem("user", JSON.stringify(userNew));

      axios.defaults.headers.common["Authorization"] = token1;
      return { ...state, loadingSignUp: false, user: action.payload.data.user };
    case SIGNUP_USER_FAIL:
      return { ...state, loadingSignUp: false, error: " " };

    case GET_TEST:
      return { ...state, loadingUser: true };
    case GET_TEST_SUCCESS:
      return { ...state, loadingUser: false, test: action.payload.data };
    case GET_TEST_FAIL:
      return { ...state, loadingUser: false, error: "Error sending Trip info" };

    case GET_USER:
      return { ...state, loadingUser: true };
    case GET_USER_SUCCESS:
      return { ...state, loadingUser: false, user: action.payload.data.user };
    case GET_USER_FAIL:
      return { ...state, loadingUser: false, error: "Error sending Trip info" };

    case GET_USER_WITH_ID:
      return { ...state, loadingUserById: true };
    case GET_USER_WITH_ID_SUCCESS:
      return { ...state, loadingUserById: false, userById: action.payload.data.user };
    case GET_USER_WITH_ID_FAILURE:
      return { ...state, loadingUserById: false };

    case UPDATE_USER:
      return { ...state, loading: true };
    case UPDATE_USER_SUCCESS:
      var currentUser = JSON.parse(localStorage.getItem("user"));
      var updatedUser = { ...currentUser, ...action.payload.data.user };
      localStorage.setItem("user", JSON.stringify(updatedUser));
      return { ...state, loading: false, user: action.payload.data.user };
    case UPDATE_USER_FAIL:
      return { ...state, loading: false, error: "Error updating user info" };

    case CREATE_COMPANY:
      return { ...state, loadingCompany: true };
    case CREATE_COMPANY_SUCCESS:
      const companyNew = action.payload.data.company;
      localStorage.setItem("company", JSON.stringify(companyNew));
      return { ...state, loadingCOMPANY: false, company: action.payload.data.company };
    case CREATE_COMPANY_FAIL:
      return { ...state, loadingCompany: false, error: "Error creating Company" };

    case GET_CURRENT_COMPANY:
      return { ...state, loadingCOMPANY: true };
    case GET_CURRENT_COMPANY_SUCCESS:
      return {
        ...state,
        loadingCOMPANY: false,
        company: action.payload.data.companies,
        currentCompany: action.payload.data.companies,
      };
    case GET_CURRENT_COMPANY_FAIL:
      return { ...state, loadingCOMPANY: false, error: "Error getting companies" };

    case UPDATE_COMPANY:
      return { ...state, loading: true };
    case UPDATE_COMPANY_SUCCESS:
      localStorage.setItem("company", JSON.stringify(action.payload.data.company));
      return { ...state, loading: false };
    case UPDATE_COMPANY_FAIL:
      return { ...state, loading: false, error: "Error updating user info" };

    case PASSWORD_RESET_USER:
      return { ...state, loadingUser: true };
    case PASSWORD_RESET_USER_SUCCESS:
      return { ...state, loadingUser: false, user: action.payload.data.user };
    case PASSWORD_RESET_USER_FAIL:
      return { ...state, loadingUser: false, error: "Error sending Trip info" };

    case GET_USER_COMPANY:
      return { ...state, loadingCOMPANY: true };
    case GET_USER_COMPANY_SUCCESS:
      return {
        ...state,
        loadingCOMPANY: false,
        companies: action.payload.data.companies,
        company: action.payload.data.companies[0],
      };
    case GET_USER_COMPANY_FAIL:
      return { ...state, loadingCOMPANY: false, error: "Error getting companies" };

    //TRIP
    case CREATE_TRIP:
      return { ...state, create_trip_loading: true };
    case CREATE_TRIP_SUCCESS:
      var updatedCompany = JSON.parse(localStorage.getItem("company"));
      updatedCompany.wallet_id.balance = action.payload.data.trip.wallet_balance;
      localStorage.setItem("company", JSON.stringify(updatedCompany));
      return {
        ...state,
        create_trip_loading: false,
        company: {
          ...state.company,
          wallet_id: { ...state.company.wallet_id, balance: action.payload.data.trip.wallet_balance },
        },
      };
    case CREATE_TRIP_FAIL:
      return { ...state, create_trip_loading: false, error: "Error creating a trip" };

    case PING_TRIP:
      return { ...state, loadingTrip: true };
    case PING_TRIP_SUCCESS:
      return { ...state, loadingTrip: false, trip: action.payload.data };
    case PING_TRIP_FAIL:
      return { ...state, loadingTrip: false, error: "Error sending Trip info" };

    case GET_TRIP:
      return { ...state, loadingTrip: true };
    case GET_TRIP_SUCCESS:
      return { ...state, loadingTrip: false, trip: action.payload.data.trip };
    case GET_TRIP_FAIL:
      return { ...state, loadingTrip: false, error: "Error sending Trip info" };

    case GET_COMPANY_DRIVER_TRIPS:
      return { ...state, loading_company_driver_trips: true };
    case GET_COMPANY_DRIVER_TRIPS_SUCCESS:
      return { ...state, loading_company_driver_trips: false, company_driver_trips: action.payload.data.trip };
    case GET_COMPANY_DRIVER_TRIPS_FAIL:
      return { ...state, loading_company_driver_trips: false, error: "Error sending Trip info" };

    case GET_PAST_TRIPS:
      return { ...state, loadingTrip: true, past_trip_page: action.payload.data.page };
    case GET_PAST_TRIPS_SUCCESS:
      let past_trips = [];
      if (state.past_trip_page > 0) {
        past_trips = state.past_trips.concat(action.payload.data.trips);
      } else {
        past_trips = action.payload.data.trips;
      }
      return { ...state, loadingTrip: false, past_trips: past_trips, past_trip_page: 0 };
    case GET_PAST_TRIPS_FAIL:
      return { ...state, loadingTrip: false, error: "Error sending Trip info" };

    case GET_AVAILABLE_TRIPS:
      return { ...state, loadingAvailableTrips: true };
    case GET_AVAILABLE_TRIPS_SUCCESS:
      return { ...state, loadingAvailableTrips: false, available_trips: action.payload.data.trips };
    case GET_AVAILABLE_TRIPS_FAILURE:
      return { ...state, loadingAvailableTrips: false, error: "Error getting available trips" };

    case UPDATE_TRIP_STATUS:
      return { ...state, loadingTripUpdate: true };
    case UPDATE_TRIP_STATUS_SUCCESS:
      return { ...state, loadingTripUpdate: false };
    case UPDATE_TRIP_STATUS_FAILURE:
      return { ...state, loadingTripUpdate: false, error: "Error updating trip status" };

    case GET_COMPANY_AVAILABLE_DRIVERS:
      return { ...state, loadingAvailableDriver: true };
    case GET_COMPANY_AVAILABLE_DRIVERS_SUCCESS:
      return { ...state, loadingAvailableDriver: false, available_drivers: action.payload.data.drivers };
    case GET_COMPANY_AVAILABLE_DRIVERS_FAIL:
      return { ...state, loadingAvailableDriver: false, error: "Error getting available drivers" };

    case GET_COMPANY_AVAILABLE_DRIVERS_BY_SIZE:
      return { ...state, loadingAvailableDriverBySize: true };
    case GET_COMPANY_AVAILABLE_DRIVERS_BY_SIZE_SUCCESS:
      return { ...state, loadingAvailableDriverBySize: false, available_drivers_by_size: action.payload.data.drivers };
    case GET_COMPANY_AVAILABLE_DRIVERS_BY_SIZE_FAIL:
      return { ...state, loadingAvailableDriverBySize: false, error: "Error getting available drivers" };

    case GET_UPCOMING_TRIPS:
      return { ...state, loadingTrip: true, upcoming_trips_page: action.payload.data.page };
    case GET_UPCOMING_TRIPS_SUCCESS:
      let upcoming_trips = [];
      if (state.upcoming_trips_page > 0) {
        upcoming_trips = state.upcoming_trips.concat(action.payload.data.trips);
      } else {
        upcoming_trips = action.payload.data.trips;
      }
      return { ...state, loadingTrip: false, upcoming_trips: upcoming_trips, upcoming_trips_page: 0 };
    case GET_UPCOMING_TRIPS_FAIL:
      return { ...state, loadingTrip: false, error: "Error sending Trip info" };

    case GET_CURRENTTRIP:
      return { ...state, loading_CURRENT_TRIP: true };
    case GET_CURRENTTRIP_SUCCESS:
      let request_status = state.request_active;
      if (action.payload.data.trip.is_accepted) {
        request_status = false;
      }
      return {
        ...state,
        loading_CURRENT_TRIP: false,
        current_trip: action.payload.data.trip,
        request_active: request_status,
      };
    case GET_CURRENTTRIP_FAIL:
      return { ...state, loading_CURRENT_TRIP: false, error: "Error sending CURRENT_TRIP info" };

    case GET_TRIP_TOKEN:
      return { ...state, loadingTrip: true };
    case GET_TRIP_TOKEN_SUCCESS:
      let newTripToken = {
        token: action.payload.data.token.token,
        //trip_id: action.payload.data.tripId
      };
      state.trip_token.push(newTripToken);
      return { ...state, loadingTrip: false, trip_token: state.trip_token };
    case GET_TRIP_TOKEN_FAIL:
      return { ...state, loadingTrip: false, error: "Error sending Trip info" };

    case GET_ALL_TRIP:
      return { ...state, loadingTrip: true };
    case GET_ALL_TRIP_SUCCESS:
      return { ...state, loadingTrip: false, trips: action.payload.data.trips };
    case GET_ALL_TRIP_FAIL:
      return { ...state, loadingTrip: false, error: "Error sending Trip info" };

    case REQUEST_TRIP:
      return { ...state, loading: true };
    case REQUEST_TRIP_SUCCESS:
      return { ...state, loading: false, requested_trip: action.payload.data.trip };
    case REQUEST_TRIP_FAIL:
      return { ...state, loading: false, error: "Error sending Trip info" };

    case CANCEL_TRIP:
      return { ...state, loadingTrip: true };
    case CANCEL_TRIP_SUCCESS:
      return { ...state, loadingTrip: false, trip: action.payload.data.trip };
    case CANCEL_TRIP_FAIL:
      return { ...state, loadingTrip: false, error: "Error sending Trip info" };

    case CANCEL_CURRENT_TRIP:
      return { ...state, loadingTrip: true };
    case CANCEL_CURRENT_TRIP_SUCCESS:
      return { ...state, loadingTrip: false, current_trip: null, request_active: false, status: STATUS.AVAILABLE };
    case CANCEL_CURRENT_TRIP_FAIL:
      return { ...state, loadingTrip: false, error: "Error sending Trip info" };

    case REPORT_TRIP:
      return { ...state, loadingTrip: true };
    case REPORT_TRIP_SUCCESS:
      return { ...state, loadingTrip: false, trip: action.payload.data.trip };
    case REPORT_TRIP_FAIL:
      return { ...state, loadingTrip: false, error: "Error sending Trip info" };

    case GET_ACCEPTEDTRIP:
      return { ...state, loadingTrip: true };
    case GET_ACCEPTEDTRIP_SUCCESS:
      return {
        ...state,
        loadingTrip: false,
        accepted_trips: action.payload.data.trips,
        current_trip: action.payload.data.trips[0],
      };
    case GET_ACCEPTEDTRIP_FAIL:
      return { ...state, loadingTrip: false, error: "Error sending Trip info" };

    case GET_ONGOING_TRIP:
      return { ...state, loadingTrip: true };
    case GET_ONGOING_TRIP_SUCCESS:
      let trip = null;
      if (action.payload.data.trips[0]) {
        // eslint-disable-next-line
        trip = action.payload.data.trips[0];
      }
      return { ...state, loadingTrip: false, ongoing_trips: action.payload.data.trips };
    case GET_ONGOING_TRIP_FAIL:
      return { ...state, loadingTrip: false, error: "Error sending Trip info" };

    //COST
    case CREATE_COST:
      return { ...state, loadingCost: true };
    case CREATE_COST_SUCCESS:
      return { ...state, loadingCost: false, cost: action.payload.data.cost };
    case CREATE_COST_FAIL:
      return { ...state, loadingCost: false, error: "Error creating cost" };

    case SET_DRIVER_LOCATION:
      return { ...state, loadingLocation: false, driverLocation: action.payload.data };

    //Notification
    case PUSH_NOTIFICATION_TOKEN_REGISTRATION:
      return { ...state, loadingLogin: true };
    case PUSH_NOTIFICATION_TOKEN_REGISTRATION_SUCCESS:
      return { ...state };
    case PUSH_NOTIFICATION_TOKEN_REGISTRATION_FAIL:
      return { ...state };

    case GET_UPLOAD:
      return { ...state, loadingImage: true };
    case GET_UPLOAD_SUCCESS:
      return { ...state, loadingImage: false, imageToken: action.payload.data.upload.url };
    case GET_UPLOAD_FAIL:
      return { ...state, loadingImage: false, error: "Error requesting upload url" };

    case CREATE_VEHICLE:
      return { ...state, loadingVehicle: true };
    case CREATE_VEHICLE_SUCCESS:
      return { ...state, loadingVehicle: false, vehicle: action.payload.data.vehicle };
    case CREATE_VEHICLE_FAIL:
      return { ...state, loadingVehicle: false, error: "Error creating vehicle" };

    case CHANGE_PASSWORD:
      return { ...state, loadingPasswordChange: true };
    case CHANGE_PASSWORD_SUCCESS:
      return { ...state, loadingPasswordChange: false };
    case CHANGE_PASSWORD_FAIL:
      return { ...state, loadingPasswordChange: false, error: "Error changing password!" };

    case GET_VEHICLES:
      return { ...state, loadingVehicles: true };
    case GET_VEHICLES_SUCCESS:
      return { ...state, loadingVehicles: false, vehicles: action.payload.data.vehicles };
    case GET_VEHICLES_FAIL:
      return { ...state, loadingVehicles: false, error: "Error getting vehicles" };

    case GET_VEHICLE:
      return { ...state, loadingVehicle: true };
    case GET_VEHICLE_SUCCESS:
      return { ...state, loadingVehicle: false, vehicle: action.payload.data.vehicle };
    case GET_VEHICLE_FAIL:
      return { ...state, loadingVehicle: false, error: "Error getting vehicle" };

    case UPDATE_VEHICLE:
      return { ...state, loadingVehicle: true };
    case UPDATE_VEHICLE_SUCCESS:
      return { ...state, loadingVehicle: false, vehicle: action.payload.data.vehicle };
    case UPDATE_VEHICLE_FAIL:
      return { ...state, loadingVehicle: false, error: "Error getting vehicle" };

    case DELETE_VEHICLE:
      return { ...state, loadingDeleteVehicle: true };
    case DELETE_VEHICLE_SUCCESS:
      return { ...state, loadingDeleteVehicle: false };
    case DELETE_VEHICLE_FAIL:
      return { ...state, loadingDeleteVehicle: false, error: "Error deleting vehicle" };

    case ADD_DRIVER_TO_COMPANY:
      return { ...state, loadingDriver: true };
    case ADD_DRIVER_TO_COMPANY_SUCCESS:
      return { ...state, loadingDriver: false, driver: action.payload.data.driver };
    case ADD_DRIVER_TO_COMPANY_FAIL:
      return { ...state, loadingDriver: false, error: "Error creating Company" };

    case REMOVE_DRIVER:
      return { ...state, loadingRemoveDriver: true };
    case REMOVE_DRIVER_SUCCESS:
      return { ...state, loadingRemoveDriver: false };
    case REMOVE_DRIVER_FAIL:
      return { ...state, loadingRemoveDriver: false, error: "Error removing driver" };

    case GET_COMPANY_DRIVERS:
      return { ...state, loadingDrivers: true };
    case GET_COMPANY_DRIVERS_SUCCESS:
      return { ...state, loadingDrivers: false, drivers: action.payload.data.drivers };
    case GET_COMPANY_DRIVERS_FAIL:
      return { ...state, loadingDrivers: false, error: "Error getting drivers" };

    case GET_PENDING_DRIVERS:
      return { ...state, loadingPendingDrivers: true };
    case GET_PENDING_DRIVERS_SUCCESS:
      return { ...state, loadingPendingDrivers: false, pendingDrivers: action.payload.data.drivers };
    case GET_PENDING_DRIVERS_FAIL:
      return { ...state, loadingPendingDrivers: false, error: "Error getting pending invites" };

    case ACCEPT_INVITATION:
      return { ...state, loadingInvite: true };
    case ACCEPT_INVITATION_SUCCESS:
      return { ...state, loadingInvite: false, isInviteAccepted: true };
    case ACCEPT_INVITATION_FAIL:
      return { ...state, loadingInvite: false, isInviteAccepted: false };

    case GET_TRACKERS_HEALTH:
      return { ...state, loadingTrackersHealth: true };
    case GET_TRACKERS_HEALTH_SUCCESS:
      return { ...state, loadingTrackersHealth: false };
    case GET_TRACKERS_HEALTH_FAIL:
      return { ...state, loadingTrackersHealth: false };

    case VERIFY_TRACKER:
      return { ...state, loadingVerifyTracker: true };
    case VERIFY_TRACKER_SUCCESS:
      return { ...state, loadingVerifyTracker: false };
    case VERIFY_TRACKER_FAIL:
      return { ...state, loadingVerifyTracker: false };

    case FETCH_TERMINALS:
      return { ...state, loading_fetch_terminals: true };
    case FETCH_TERMINALS_SUCCESS:
      return { ...state, loading_fetch_terminals: false };
    case FETCH_TERMINALS_FAIL:
      return { ...state, loading_fetch_terminals: false };

    //Wallet
    case FUND_WALLET:
      return { ...state, fund_wallet_loading: true };
    case FUND_WALLET_SUCCESS:
      var updatedCompany2 = JSON.parse(localStorage.getItem("company"));
      updatedCompany2.wallet_id = action.payload.data;
      localStorage.setItem("company", JSON.stringify(updatedCompany2));
      return { ...state, fund_wallet_loading: false };

    case FUND_WALLET_FAIL:
      return { ...state, fund_wallet_loading: false };
    default:
      return state;
  }
}

export const MainModule = createNamedReducer({
  moduleName: "MainModule",
  reducer: reducer,
});
