import styled from "styled-components";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import React, { useState, useEffect } from "react";
import { Input } from "antd";

const InputAddress = ({ placeholder, data, setData, name, coordinates }) => {
  const [inputValue, setInputValue] = useState("");

  const handleSelect = async (address) => {
    const results = await geocodeByAddress(address);
    const latLng = await getLatLng(results[0]);

    setInputValue(address);
    setData({ ...data, [name]: address, [coordinates]: latLng });
  };

  useEffect(() => {
    if (data[name] && data[name] !== inputValue) {
      setInputValue(data[name]);
    }
  }, [data]);

  return (
    <PlacesAutocomplete
      value={inputValue}
      onChange={(value) => setInputValue(value)}
      onSelect={handleSelect}
      debounce={400}
      searchOptions={{ componentRestrictions: { country: ["ng"] } }}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div>
          <StyledInput
            {...getInputProps({
              placeholder: placeholder,
            })}
          />
          <div>
            {loading && <div>Loading...</div>}
            {suggestions.map((suggestion) => {
              const style = suggestion.active
                ? { backgroundColor: "#e27626", cursor: "pointer" }
                : { backgroundColor: "#ffffff", cursor: "pointer" };

              return (
                <div key={suggestion.placeId} {...getSuggestionItemProps(suggestion, { style })}>
                  {suggestion.description}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
};

const StyledInput = styled(Input)`
  border-radius: 5px;
  height: 50px;
  margin-bottom: 10px;
`;

export default InputAddress;
