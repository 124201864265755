import React from "react";
import { Divider, Menu, Dropdown } from "antd";
import { Link } from "react-router-dom";
import { ExclamationCircleOutlined, EnvironmentOutlined } from "@ant-design/icons";
import { authenticatedUser } from "../../helper/AuthHelper";
import { navLinks } from "../../configs/NavConfig";
import "../../styles/nav.css";

const CustomDrawer = ({ onClose }) => {
  const userDetails = authenticatedUser();
  const userType = userDetails?.user_type;
  const isStandAloneDriver = userType === "driver" && !userDetails?.is_employee;

  const getSubMenu = ({ submenu }) => {
    return (
      <Menu>
        {submenu?.map(
          (sub, i) =>
            sub?.permission?.includes(userType) && (
              <Menu.Item key={i}>
                <Link to={sub.path} onClick={onClose} className="link">
                  {sub.title}
                </Link>
              </Menu.Item>
            )
        )}
      </Menu>
    );
  };

  return (
    <ul className="drawer-list">
      {navLinks.map((menu) =>
        menu.submenu.length === 0 ? (
          (menu?.permission?.includes(userType) ||
            (menu?.permission?.includes("stand-alone") && isStandAloneDriver)) && (
            <>
              <li>
                <Link className="link" to={menu.path} onClick={onClose}>
                  <ExclamationCircleOutlined />
                  <span>{menu.title}</span>
                </Link>
              </li>
              <Divider />
            </>
          )
        ) : (
          <>
            <li key={menu.key}>
              <Dropdown overlay={getSubMenu({ submenu: menu?.submenu })}>
                <Link className="link">
                  <EnvironmentOutlined />
                  <span>{menu.title}</span>
                </Link>
              </Dropdown>
            </li>
            <Divider />
          </>
        )
      )}
    </ul>
  );
};

export default CustomDrawer;
