import React, { useState, useEffect } from "react";
import { Button, Form, Input, Spin } from "antd";
import { connect } from "react-redux";
import { reduxFunctions } from "../../helper/ReduxHelper";
import loadingIcon from "../../components/common/loadingIcon";
import "../../styles/main.css";
import { toast } from "react-toastify";
import CustomSelect from "../../components/common/Select";
import ImageUpload from "../../components/common/ImageUpload";
import { MAX_WEIGHT, TRACKER_STATUSES } from "../../constants/Enums";
import StatusBadge from "../../components/common/StatusBadge";

const VehicleForm = ({
  handleStepChange,
  data,
  handleChange,
  createVehicle,
  handleSelectChange,
  uploadUrl,
  fileList,
  loadingImage,
  imageUrl,
  setUploadUrl,
  sets3ImageUrl,
  setImageUrl,
  setFileList,
  setLoadingImage,
  s3ImageUrl,

  s3ImageUrl1,
  uploadUrl1,
  fileList1,
  imageUrl1,
  setUploadUrl1,
  sets3ImageUrl1,
  setImageUrl1,
  setFileList1,
  getTrackersHealth,

  imageUrl2,
  handleFinish,
}) => {
  const [loading, setLoading] = useState(false);
  const [trackerStatus, setTrackerStatus] = useState("pending");

  const _startLoading = () => {
    setLoading(true);
  };

  const _stopLoading = () => {
    setLoading(false);
  };

  const handleVehicleSave = async () => {
    if (data?.max_weight > MAX_WEIGHT) return toast.error(`Max weight shouldn't be more than ${MAX_WEIGHT}Tonnes`);
    _startLoading();

    const newData = { ...data, max_weight: +data.max_weight };
    if (imageUrl) {
      newData.vehicle_documents[0].image = {
        original: imageUrl,
      };
    }
    if (imageUrl1) {
      newData.vehicle_documents[1].image = {
        original: imageUrl1,
      };
    }

    if (imageUrl2) {
      newData.vehicle_documents[2].image = {
        original: imageUrl2,
      };
    }

    await createVehicle(newData)
      .then(async (res) => {
        toast.success("Vehicle successfully created");
        _stopLoading();
        handleFinish();
      })
      .catch((error) => {
        toast.error(error.error.response.data.message);
        _stopLoading();
        return error;
      });
  };

  const axleTypeOptions = [{ value: "single" }, { value: "double" }];

  const verifyTrackingId = (val) => {
    if (/^\d{15,17}$/.test(val)) {
      getTrackersHealth([val])
        .then((res) => {
          setTrackerStatus(res?.payload?.data?.[0]?.status);
        })
        .catch((e) => {
          setTrackerStatus(TRACKER_STATUSES.INVALID);
        });
    } else {
      if (val.length > 17) {
        setTrackerStatus(TRACKER_STATUSES.INVALID);
      } else {
        setTrackerStatus(TRACKER_STATUSES.PENDING);
      }
    }
  };

  const { tracking_id } = data;

  useEffect(() => {
    verifyTrackingId(tracking_id);
  }, [tracking_id]);

  return (
    <div className="vehicle-form">
      <h2>Add A Vehicle</h2>
      <Form
        layout="vertical"
        name="login-form"
        // onFinish={}
      >
        <Form.Item label="Make" className="label">
          <Input size={"large"} className="input" name="make" value={data.make} onChange={handleChange} />
        </Form.Item>
        <Form.Item label="Model" className="label">
          <Input size={"large"} className="input" name="model" value={data.model} onChange={handleChange} />
        </Form.Item>
        <Form.Item label="Color" className="label">
          <Input size={"large"} className="input" name="color" value={data.color} onChange={handleChange} />
        </Form.Item>
        <Form.Item label="Plate Number" className="label">
          <Input
            size={"large"}
            className="input"
            name="plate_number"
            value={data.plate_number}
            onChange={handleChange}
          />
        </Form.Item>
        {/*<Form.Item label="Registration Number" className='label'>*/}
        {/*    <Input size={"large"} className='input' name='registration_number' value={data.registration_number} onChange={handleChange}/>*/}
        {/*</Form.Item>*/}
        {/*<Form.Item label="Engine Number" className='label'>*/}
        {/*    <Input size={"large"} className='input' name='engine_number' value={data.engine_number} onChange={handleChange}/>*/}
        {/*</Form.Item>*/}

        {/*<Form.Item label="Chassis Number" className='label'>*/}
        {/*    <Input size={"large"} className='input' name='chassis_number' value={data.chassis_number} onChange={handleChange}/>*/}
        {/*</Form.Item>*/}
        <Form.Item label="Tracking ID (Optional)" className="label" style={{ marginBottom: "10px" }}>
          <Input size={"large"} className="input" name="tracking_id" value={data.tracking_id} onChange={handleChange} />
        </Form.Item>
        <div style={{ marginBottom: "25px" }}>
          <StatusBadge form status={trackerStatus} />
        </div>

        <Form.Item label="Axle Type" className="label">
          <div className="selectContainer2">
            <CustomSelect
              options={axleTypeOptions}
              size="large"
              name="axle_type"
              handleChange={handleSelectChange}
              value={data.axle_type}
              bordered={false}
            />
          </div>
        </Form.Item>

        <Form.Item label="Max Weight Supported (Tonne Capacity)" className="label" style={{ marginBottom: "10px" }}>
          <Input
            size={"large"}
            type="number"
            max={MAX_WEIGHT}
            className="input"
            name="max_weight"
            value={data.max_weight}
            onChange={handleChange}
          />
        </Form.Item>
        {data?.max_weight > MAX_WEIGHT && <p style={{ color: "red" }}>Weight shouldn't exceed {MAX_WEIGHT}Tonnes</p>}

        <Form.Item
          label="Upload Picture of Road Worthiness"
          className="label"
          style={{ minHeight: "145px", marginTop: "40px" }}
        >
          <ImageUpload
            data={data}
            loadingImage={loadingImage}
            uploadUrl={uploadUrl}
            fileList={fileList}
            imageUrl={imageUrl}
            setUploadUrl={setUploadUrl}
            sets3ImageUrl={sets3ImageUrl}
            setImageUrl={setImageUrl}
            setFileList={setFileList}
            setLoadingImage={setLoadingImage}
            s3ImageUrl={s3ImageUrl}
            listType="picture"
            button={true}
          />
        </Form.Item>

        <Form.Item label="Upload Picture of Insurance" className="label" style={{ minHeight: "145px" }}>
          <ImageUpload
            data={data}
            loadingImage={loadingImage}
            setLoadingImage={setLoadingImage}
            uploadUrl={uploadUrl1}
            fileList={fileList1}
            imageUrl={imageUrl1}
            setUploadUrl={setUploadUrl1}
            sets3ImageUrl={sets3ImageUrl1}
            setImageUrl={setImageUrl1}
            setFileList={setFileList1}
            s3ImageUrl={s3ImageUrl1}
            listType="picture"
            button={true}
          />
        </Form.Item>

        <div className="buttons">
          <Button className="b2 alt-button" size={"large"} onClick={() => handleStepChange(1)}>
            Back
          </Button>
          <Button className=" all-button b2" type="primary" size={"large"} onClick={handleVehicleSave}>
            {loading ? <Spin indicator={loadingIcon} /> : <span>Submit</span>}
          </Button>
        </div>
      </Form>
    </div>
  );
};

const mapStateToProps = ({ user, vehicle, getTrackersHealth }) => ({ user, vehicle, getTrackersHealth });

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(VehicleForm);
