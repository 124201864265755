import React, { useState, useEffect } from "react";
import "../../styles/main.css";
import { connect } from "react-redux";
import LineComponent from "./components/LineComponent";
import { reduxFunctions } from "../../helper/ReduxHelper";
import moment from "moment";
import { distancePerDay, revenuePercentage } from "../../helper/DataHelper";

const DayDistance = (props) => {
  const [data, setData] = useState([{ y: 0, x: moment().valueOf() }]);
  const [revenueData, setRevenueData] = useState(props.paymentHistory);

  const getPaymentHistory = async () => {
    await props
      .getPaymentHistory()
      .then((res) => {
        setRevenueData(res.payload.data.payments);
        setData(distancePerDay(res.payload.data.payments));
      })
      .catch((error) => {
        // toast.error('Error retrieving distance data!')
        return error;
      });
  };

  useEffect(() => {
    getPaymentHistory();
  }, []);

  const revenue = revenuePercentage(
    data[data.length - 2] ? data[data.length - 2].y : null,
    data[data.length - 1] ? data[data.length - 1].y : null
  );

  return (
    <LineComponent
      data={data}
      head="Distance per day"
      dateData={
        revenueData[revenueData.length - 1]
          ? moment(revenueData[revenueData.length - 1].start_time).format("MMMM Do YYYY")
          : "Pending"
      }
      amount={data[data.length - 1] ? `${Number.parseFloat(data[data.length - 1].y).toFixed(2)} KM` : "0 KM"}
      growth={revenue.growth}
      growthInfo={revenue.info}
      info={"Total gross income figure based from the date range given above."}
      title="Distance"
      status={revenue.loss}
    />
  );
};

const mapStateToProps = ({ user, paymentHistory, payouts }) => ({ user, paymentHistory, payouts });

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(DayDistance);
