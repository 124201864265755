

export const BANKS =
    [
        { "id": "1", "value": "Access Bank" ,"code":"044", image: 'https://brandspurng.com/wp-content/uploads/2019/03/Access-and-Diamond-Bank-logo-brand-spur-nigeria-1.jpg' },
        { "id": "2", "value": "Citibank","code":"023", image: 'https://i.pinimg.com/originals/84/c7/fd/84c7fd365dc566b444c6355d2ce3d571.jpg' },
        { "id": "3", "value": "Diamond Bank","code":"063", image: 'https://brandcom.ng/wp-content/uploads/2017/08/Diamond-Bank-Logo.jpg' },
        { "id": "4", "value": "Dynamic Standard Bank","code":"" },
        { "id": "5", "value": "Ecobank Nigeria","code":"050", image: 'https://brandcom.ng/wp-content/uploads/2017/10/Eco-Bank-Logo.jpg' },
        { "id": "6", "value": "Fidelity Bank Nigeria","code":"070", image: 'https://nipc.gov.ng/wp-content/uploads/2019/01/fidelity-bank-logo.jpg' },
        { "id": "7", "value": "First Bank of Nigeria","code":"011", image: 'https://www.socialbakers.com/www/storage/www/reports/2018-05-01/twitter/278066421.jpg' },
        { "id": "8", "value": "First City Monument Bank","code":"214", image: 'https://financialallianceforwomen.org/wp-content/uploads/2019/09/FCMB-Logo-600pxls-x-600pxls.jpg' },
        { "id": "9", "value": "Guaranty Trust Bank","code":"058", image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/1/14/GTBank_logo.svg/1200px-GTBank_logo.svg.png' },
        { "id": "10", "value": "Heritage Bank Plc","code":"030", image: 'https://explain.com.ng/wp-content/uploads/2020/05/WeChat-Image_20200517205339.jpg' },
        { "id": "11", "value": "Jaiz Bank","code":"301", image: "http://allvectorlogo.com/img/2016/06/jaiz-bank-logo.png" },
        { "id": "12", "value": "Keystone Bank Limited","code":"082", image: 'https://www.fintechfutures.com/files/2017/01/keystone-bank-logo.png' },
        { "id": "13", "value": "Providus Bank Plc","code":"101", image: 'https://netstorage-legit.akamaized.net/images/2bb7ed1fce355f32.jpg' },
        { "id": "14", "value": "Polaris Bank","code":"076", image: 'https://upload.wikimedia.org/wikipedia/commons/f/f7/Polaris-Bank-Limited.png' },
        { "id": "15", "value": "Stanbic IBTC Bank Nigeria Limited","code":"221", image: 'https://brandcom.ng/wp-content/uploads/2019/01/Stanbic-Bank-Logo.jpg' },
        { "id": "16", "value": "Standard Chartered Bank","code":"068", image: 'https://financialit.net/sites/default/files/standard-chartered-1.jpg' },
        { "id": "17", "value": "Sterling Bank","code":"232", image: 'https://4.bp.blogspot.com/-Rz97JMi7hjE/XDW4cYoHxpI/AAAAAAAAfg8/FPmIuulWp2gk-4LVaUEJMpyNS6tv2XbTgCLcBGAs/s1600/Sterling%2Bnew%2Blogo.png' },
        { "id": "18", "value": "Suntrust Bank Nigeria Limited","code":"100", image: 'https://www.seekpng.com/png/detail/216-2160340_suntrust-bank-logo.png' },
        { "id": "19", "value": "Union Bank of Nigeria","code":"032", image: 'https://i0.wp.com/nairametrics.com/wp-content/uploads/2015/10/Union-Bank-New-Logo.png' },
        { "id": "20", "value": "United Bank for Africa","code":"033", image: 'https://www.ubagroup.com/wp-content/uploads/2018/09/UBA-logo-5.gif' },
        { "id": "21", "value": "Unity Bank Plc","code":"215", image: 'https://i1.wp.com/www.brandcrunch.com.ng/wp-content/uploads/2018/11/unity-bank.jpg?fit=500%2C350&ssl=1' },
        { "id": "22", "value": "Wema Bank","code":"035", image: 'https://live.mrf.io/statics/i/ps/storage.googleapis.com/thisday-846548948316-wp-data/wp-media/2018/08/281aaedc-wema-bank.jpg?width=1200&enable=upscale' },
        { "id": "23", "value": "Zenith Bank","code":"057", image: 'https://upload.wikimedia.org/wikipedia/en/thumb/d/d6/Zenith_Bank_logo.svg/1200px-Zenith_Bank_logo.svg.png' }
    ];