import React, { useState } from "react";
import { Switch } from "antd";
import { MailOutlined, GlobalOutlined } from "@ant-design/icons";
import "../../styles/main.css";

const Notification = () => {
  const [emailNot, setEmailNot] = useState(false);

  return (
    <div className="notification">
      <h2 className="not-header">Notification</h2>
      <div className="notify">
        <div className="inner">
          <MailOutlined className="icon" />
          <div>
            <h2>Email Notifications</h2>
            <p>You will receive daily email notifications.</p>
          </div>
        </div>
        <Switch
          defaultChecked
          value={emailNot}
          onChange={(value) => {
            setEmailNot(value);
          }}
        />
      </div>
      <div className="notify">
        <div className="inner">
          <GlobalOutlined className="icon" />
          <div>
            <h2>Web Notifications</h2>
            <p>You will receive daily web notifications.</p>
          </div>
        </div>
        <Switch
          defaultChecked
          onChange={(value) => {
            setEmailNot(value);
          }}
        />
      </div>
    </div>
  );
};

export default Notification;
