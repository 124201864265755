import React from "react";
import { Input } from "antd";
import styled from "styled-components";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { BiSearch } from "react-icons/bi";
import { useEffect } from "react";

const LocationInput = ({
  inputValue,
  setInputValue,
  placeholder,
  setLocation,
  setIsFiltering,
  isFiltering,
  setNearBy,
}) => {
  const handleSelect = async (address) => {
    const results = await geocodeByAddress(address);
    const latLng = await getLatLng(results[0]);
    setInputValue(address);
    setLocation({ latitude: latLng.lat, longitude: latLng.lng });
    setIsFiltering(true);
    setNearBy(false);
  };

  useEffect(() => {
    if (!isFiltering) return setInputValue("");
  }, [isFiltering]);

  return (
    <PlacesAutocomplete
      value={inputValue}
      onChange={(value) => setInputValue(value)}
      onSelect={handleSelect}
      debounce={400}
      searchOptions={{ componentRestrictions: { country: ["ng"] } }}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <Container>
          <div className="input-cont">
            <BiSearch className="search-icon" />

            <Input
              type="search"
              className="input-field"
              // onChange={(e) => e.preventDefault()}
              {...getInputProps({
                placeholder: placeholder,
              })}
            />
          </div>

          <div className="suggestions">
            {loading && <div>Loading...</div>}
            {suggestions.map((suggestion) => {
              const style = suggestion.active
                ? { backgroundColor: "#e27626", cursor: "pointer" }
                : { backgroundColor: "#ffffff", cursor: "pointer" };

              return (
                <div key={suggestion.placeId} {...getSuggestionItemProps(suggestion, { style })}>
                  {suggestion.description}
                </div>
              );
            })}
          </div>
        </Container>
      )}
    </PlacesAutocomplete>
  );
};

const Container = styled.div`
  padding: 12px 10px;
  border: 1px solid #667085bf;
  border-radius: 10px;
  position: relative;

  .input-cont {
    display: flex;
    align-items: center;

    .search-icon {
      font-size: 17px;
    }
  }

  input {
    background-color: transparent;
    border: none;
    outline: none;
    font-size: 15px;
    padding: 0 6px;
    color: #101828;

    &::placeholder {
      color: #101828;
    }

    &:focus {
      box-shadow: none;
    }
  }

  .suggestions {
    position: absolute;
    top: 50px;
  }
`;

export default LocationInput;
