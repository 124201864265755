const environment = process.env.REACT_APP_ENV || "default";

const version = "1.3";

export const envConfig = {
  default: {
    REACT_APP_BASEURL: process.env.REACT_APP_DEV_BASEURL,
    REACT_APP_MAPBOX_TOKEN: process.env.REACT_APP_MAPBOX_TOKEN,
    REACT_APP_GOOGLE_API_KEY: process.env.REACT_APP_GOOGLE_API_KEY,
    REACT_APP_PUBNUB_PUBLISH_KEY: process.env.REACT_APP_DEV_PUBNUB_PUBLISH_KEY,
    REACT_APP_PUBNUB_SUBSCRIBE_KEY: process.env.REACT_APP_DEV_PUBNUB_SUBSCRIBE_KEY,
    REACT_APP_PAYSTACK_PUBLIC_KEY: process.env.REACT_APP_DEV_PAYSTACK_PUBLIC_KEY,
    REACT_APP_PAYSTACK_KEY: process.env.REACT_APP_DEV_PAYSTACK_KEY,
    REACT_APP_GOTRUCK_SIGNATURE: process.env.REACT_APP_GOTRUCK_SIGNATURE,
    REACT_APP_TRIP_SERVER: process.env.REACT_APP_DEV_TRIP_SERVER,
    REACT_APP_SOCKET_URL: process.env.REACT_APP_DEV_SOCKET_URL,
    version,
  },
  development: {
    REACT_APP_BASEURL: process.env.REACT_APP_DEV_BASEURL,
    REACT_APP_MAPBOX_TOKEN: process.env.REACT_APP_MAPBOX_TOKEN,
    REACT_APP_GOOGLE_API_KEY: process.env.REACT_APP_GOOGLE_API_KEY,
    REACT_APP_PUBNUB_PUBLISH_KEY: process.env.REACT_APP_DEV_PUBNUB_PUBLISH_KEY,
    REACT_APP_PUBNUB_SUBSCRIBE_KEY: process.env.REACT_APP_DEV_PUBNUB_SUBSCRIBE_KEY,
    REACT_APP_PAYSTACK_PUBLIC_KEY: process.env.REACT_APP_DEV_PAYSTACK_PUBLIC_KEY,
    REACT_APP_PAYSTACK_KEY: process.env.REACT_APP_DEV_PAYSTACK_KEY,
    REACT_APP_GOTRUCK_SIGNATURE: process.env.REACT_APP_GOTRUCK_SIGNATURE,
    REACT_APP_TRIP_SERVER: process.env.REACT_APP_DEV_TRIP_SERVER,
    REACT_APP_SOCKET_URL: process.env.REACT_APP_DEV_SOCKET_URL,
    version,
  },
  production: {
    REACT_APP_BASEURL: process.env.REACT_APP_PROD_BASEURL,
    REACT_APP_MAPBOX_TOKEN: process.env.REACT_APP_MAPBOX_TOKEN,
    REACT_APP_GOOGLE_API_KEY: process.env.REACT_APP_GOOGLE_API_KEY,
    REACT_APP_PUBNUB_PUBLISH_KEY: process.env.REACT_APP_PROD_PUBNUB_PUBLISH_KEY,
    REACT_APP_PUBNUB_SUBSCRIBE_KEY: process.env.REACT_APP_PROD_PUBNUB_SUBSCRIBE_KEY,
    REACT_APP_PAYSTACK_PUBLIC_KEY: process.env.REACT_APP_PROD_PAYSTACK_PUBLIC_KEY,
    REACT_APP_PAYSTACK_KEY: process.env.REACT_APP_PROD_PAYSTACK_KEY,
    REACT_APP_GOTRUCK_SIGNATURE: process.env.REACT_APP_GOTRUCK_SIGNATURE,
    REACT_APP_TRIP_SERVER: process.env.REACT_APP_PROD_TRIP_SERVER,
    REACT_APP_SOCKET_URL: process.env.REACT_APP_DEV_SOCKET_URL,
    version,
  },
}[environment];
