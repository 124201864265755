import styled from "styled-components";
import ReactSnackBar from "react-js-snackbar";
import { Button, Spin, Avatar, Image, Table, Modal } from "antd";
import React, { useState, useEffect } from "react";
import moment from "moment";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { reduxFunctions } from "../../helper/ReduxHelper";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Empty from "../../assets/svgs/empty.svg";
import emptyImg from "../../assets/empty.jpeg";
import loadingIcon from "../common/loadingIcon";
import DriverDetailsUpdated from "../../screens/DriverDetailsUpdated";
import InsufficientBalanceModal from "../wallet/InsufficientBalanceModal";
import { formatMoney } from "../../helper/UtilityHelper";
import { InfoCircleOutlined } from "@ant-design/icons";
import PaymentProcessing from "../wallet/PaymentProcessing";
import { authenticatedCompany } from "../../helper/AuthHelper";

const AssignDriver = (props) => {
  const [driverData, setDriverData] = useState([]);
  const [assignedDriver, setAssignedDriver] = useState({});
  const [viewDriverId, setViewDriverId] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [notificationState, setNotificationState] = useState({ show: false, showing: false });
  const [openWalletModal, setOpenWalletModal] = useState(false);
  const [processingModalOpen, setProcessingModalOpen] = useState(false);
  const [fundAmount, setFundAmount] = useState("");
  const [topup, setTopup] = useState(false);
  let history = useHistory();

  const company = authenticatedCompany();

  const walletBalance = company?.wallet_id?.balance || 0;
  const freeTrip = company?.wallet_id?.is_bottomless;

  const show = () => {
    if (notificationState.showing) return;

    setNotificationState({ show: true, showing: true });
    setTimeout(() => {
      setNotificationState({ show: false, showing: false });
    }, 2000);
  };

  const tripData = {
    cost_id: props.data.cost_id,
    container_number: props.data.container_number,
    drop_off_address: props.data.dropOffAddress,
    drop_off_lng: props.data.dropOffCoordinates.lng,
    drop_off_lat: props.data.dropOffCoordinates.lat,
    is_container_owner: props.data?.is_container_owner === "yes" ? true : false,
    tdo_expiry_date: moment().add(3, "days"),
    end_address: props.data.endAddress,
    end_lng: props.data.endCoordinates.lng,
    end_lat: props.data.endCoordinates.lat,
    item_size: props.data?.item_size,
    trip_category: props.data?.trip_category,
    cargo_type: props.data.cargo_type,
    cargo_value: props.data.cargo_value,
    cargo_category: props.data.cargo_category,
    cargo_description: props.data.cargo_description,
    cargo_image: props?.data?.cargo_image,
    start_address: props.data.startAddress,
    start_lng: props.data?.startCoordinates?.lng,
    start_lat: props.data?.startCoordinates?.lat,
    start_time: props.data.startDate,
    offloading_duration: 86400000 * +props.data?.offloading_duration,
    tonnage: props.data?.tonnage,
    company_id: company?._id,
    customer_comment: `${props.data.customerFullname} ${props.data.customerEmail}`,
    customer_email: props.data.customerEmail,
    customer_fullname: props.data.customerFullname,
  };

  const payload = { ...tripData, driver_id: assignedDriver._id, vehicle_id: assignedDriver.vehicle_id };

  const handleCreateTrip = () => {
    if (!assignedDriver._id) {
      show();
      return;
    }

    if (!freeTrip && walletBalance < process.env.REACT_APP_TRIP_FEE) return setOpenWalletModal(true);
    // if (!freeTrip && walletBalance < 1000000) return setOpenWalletModal(true); //test condition

    props
      .createTrip(payload)
      .then((res) => {
        toast.success("Trip created successfully!");
        history.push("/app/trips/upcoming-trips");
      })
      .catch((error) => {
        toast.error(error.error.response.data.message);
        return error;
      });
  };

  const getDriverData = { item_size: props.data.item_size, company_id: company?._id };

  useEffect(() => {
    props
      .getCompanyAvailableDriversByItemSize(getDriverData)
      .then((res) => {
        const newData = [];
        res.payload.data.drivers?.forEach((driver) => {
          newData.push({ ...driver, image: driver.profile_picture?.original });
        });
        setDriverData(newData);
      })
      .catch((error) => {
        return error;
      });
  }, []);

  const handleDriverDetails = (id) => {
    setModalOpen(true);
    setViewDriverId(id);
  };

  const handleClose = () => {
    setModalOpen(false);
    setViewDriverId("");
  };
  const handleCloseWalletModal = () => {
    setOpenWalletModal(false);
    setTopup(false);
  };

  const EmptyIcon = (
    <div>
      <img src={Empty} alt="Empty list" style={{ width: "100px" }} />
      <p>There is no available driver with the required truck type</p>
    </div>
  );

  const availableDriversColumn = [
    {
      title: "",
      dataIndex: "image",
      key: "image",
      // responsive: ['md'],
      render: (text, record) => (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <Avatar shape="square" src={text ? <Image src={text} /> : <Image src={emptyImg} />} />
          <p
            style={{ fontWeight: "bold", fontSize: "10px", cursor: "pointer", color: "#E27626" }}
            onClick={() => handleDriverDetails(record._id)}
          >
            View driver details
          </p>
        </div>
      ),
    },
    {
      title: "Full name",
      dataIndex: "full_name",
      key: "full_name",
      // responsive: ['md'],
    },
    {
      title: "Phone number",
      dataIndex: "phone_number",
      key: "phone_number",
      responsive: ["md"],
    },
    {
      title: "Email Address",
      dataIndex: "email",
      key: "email",
      responsive: ["md"],
    },
    {
      title: "",
      dataIndex: "assign_truck",
      key: "assign_truck",
      render: (text, record) => (
        <p
          style={{ color: assignedDriver._id === record._id ? "green" : "#e27626", fontSize: "", cursor: "pointer" }}
          onClick={() => setAssignedDriver(record)}
        >
          {assignedDriver._id === record._id ? "Assigned" : "Assign"}
        </p>
      ),
    },
  ];

  return (
    <>
      <StyledContainer>
        <ReactSnackBar Icon={<AiOutlineInfoCircle />} Show={notificationState.show}>
          Please assign a driver
        </ReactSnackBar>
        <h1 className="header">Available Drivers</h1>
        <div className="drivers-container available-drivers">
          <Table
            className="table"
            locale={{ emptyText: EmptyIcon }}
            columns={availableDriversColumn}
            dataSource={driverData}
            size="large"
            pagination={false}
          />
        </div>

        {!freeTrip && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
              <InfoCircleOutlined style={{ color: "#E27626" }} />
              <p style={{ textAlign: "center" }}>
                Creating a trip attracts the sum of{" "}
                <span style={{ fontWeight: "500" }}>
                  {formatMoney({ value: +process.env.REACT_APP_TRIP_FEE, money: true })}
                </span>
                , which will be deducted from your wallet.
              </p>
            </div>
          </div>
        )}

        <div className={"btn-container"}>
          <Button onClick={() => props.setCurrentStep(2)}>Back</Button>
          <Button type={"primary"} onClick={handleCreateTrip}>
            {props.create_trip_loading ? <Spin indicator={loadingIcon} /> : "Create Trip"}
          </Button>
        </div>
      </StyledContainer>

      <Modal visible={modalOpen} width={700} centered onCancel={handleClose} footer={""}>
        <DriverDetailsUpdated id={viewDriverId} />
      </Modal>

      <Modal visible={openWalletModal} width={500} centered onCancel={handleCloseWalletModal} footer={""}>
        <InsufficientBalanceModal
          topup={topup}
          setTopup={setTopup}
          fundAmount={fundAmount}
          setFundAmount={setFundAmount}
          processingModalOpen={processingModalOpen}
          setProcessingModalOpen={setProcessingModalOpen}
          setOpenWalletModal={setOpenWalletModal}
        />
      </Modal>
      <Modal
        visible={processingModalOpen}
        maskClosable={false}
        width={500}
        centered
        onCancel={() => setProcessingModalOpen(false)}
        footer={""}
      >
        <PaymentProcessing fundAmount={fundAmount} />
      </Modal>
    </>
  );
};

const StyledContainer = styled.div`
  .drivers-container {
    min-height: 350px;
    background: white;
    padding: 20px;
    border-radius: 5px;
  }

  input {
    border-radius: 5px;
    height: 50px;
  }

  .btn-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
  }

  button {
    width: 150px;
    height: 50px;
    border-radius: 5px;
  }

  .sub-title {
    color: #344054;
    font-weight: 600;
  }

  label {
    font-weight: bold;
    font-size: 18px;
    color: ${({ theme }) => theme?.colors?.secondary};
    color: #344054;
  }
`;

const mapStateToProps = ({ createCost, loadingCost, available_drivers, create_trip_loading }) => ({
  createCost,
  loadingCost,
  available_drivers,
  create_trip_loading,
});

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(AssignDriver);
