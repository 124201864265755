import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { reduxFunctions } from "../../helper/ReduxHelper";
import { ClipLoader } from "react-spinners";
import { formatMoney } from "../../helper/UtilityHelper";
import Icon, { CheckCircleOutlined } from "@ant-design/icons";

const PaymentProcessing = (props) => {
  return (
    <Container>
      <div className="topup-container">
        {props.fund_wallet_loading ? (
          <>
            <div>
              <ClipLoader loading={true} color="#e27626" />
            </div>
            <h3>Processing</h3>
            <p>Please wait while we process your payment</p>
          </>
        ) : (
          <>
            <div className="icon">
              <Icon component={CheckCircleOutlined} />
            </div>
            <h3>Top-up Success</h3>
            <p>
              You have successfully added <span>{formatMoney({ value: +props.fundAmount, money: true })}</span>
              <br /> to your wallet.
            </p>
          </>
        )}
      </div>
    </Container>
  );
};

const Container = styled.div`
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;

  .topup-container {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;

    h3 {
      text-align: center;
      font-weight: 500;
      font-size: 22px;
      color: #e27626;
      margin: 0;
    }

    p {
      font-size: 16px;
      color: #667085;
    }

    span {
      font-weight: bold;
    }

    .icon {
      font-size: 36px;
      color: #e27626;
    }
  }
`;

const mapStateToProps = ({ fundWallet, fund_wallet_loading }) => ({ fundWallet, fund_wallet_loading });

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(PaymentProcessing);
