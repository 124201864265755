import React, { useState, useEffect } from "react";
import queryString from "query-string";
import { connect } from "react-redux";
import { reduxFunctions } from "../helper/ReduxHelper";
import Lottie from "react-lottie";
import doneAnimation from "../lotties/done.json";
import loadingAnimation from "../lotties/loading.json";
import { toast } from "react-toastify";
import AuthLayout from "../components/layout-components/AuthLayout";
import styled from "styled-components";

const AcceptInvitation = (props) => {
  const [loading, setLoading] = useState(false);

  const loadingOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const doneOptions = {
    loop: false,
    autoplay: true,
    animationData: doneAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const _startLoading = () => {
    setLoading(true);
  };

  const _stopLoading = () => {
    setLoading(false);
  };

  useEffect(() => {
    const result = queryString.parse(props.location.search);
    _startLoading();
    props
      .acceptInvitation(result.company, `Bearer ${result.token}`)
      .then((res) => {
        toast.success("Successfully accepted");
        _stopLoading();
      })
      .catch((error) => {
        _stopLoading();
        toast.error("An error occured");
        return error;
      });
  }, []);

  return (
    <AuthLayout>
      <Container>
        <h2>Accept Invitation</h2>
        <div className="check">
          <Lottie
            options={!loading && props.isInviteAccepted ? doneOptions : loadingOptions}
            height={150}
            width={150}
          />
        </div>
      </Container>
    </AuthLayout>
  );
};

const Container = styled.div`
  .check {
    margin: 20px auto;
  }
`;

const mapStateToProps = ({ isInviteAccepted }) => ({ isInviteAccepted });

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(AcceptInvitation);
