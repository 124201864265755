import styled from "styled-components";
import ReactSnackBar from "react-js-snackbar";
import { Form, Input, Radio, Button, InputNumber } from "antd";
import React, { useRef, useState } from "react";
import PrimaryCard from "./PrimaryCard";
import SecondaryCard from "./SecondaryCard";
import { CARGO_CATEGORIES, MAX_TONNAGE, truckOptions } from "../../constants/Enums";
import CustomSelect from "../common/Select";
import { AiOutlineInfoCircle } from "react-icons/ai";

const ContainerDetailsForm = ({ data, handleChange, setData, setCurrentStep }) => {
  const [notificationState, setNotificationState] = useState({
    show: false,
    showing: false,
  });
  const containerRef = useRef(null);
  const looseGoodsRef = useRef(null);

  const categoryOptions = [
    { label: "Construction Materials", value: CARGO_CATEGORIES.CONSTRUCTION_MATERIALS },
    { label: "Automobiles", value: CARGO_CATEGORIES.AUTOMOBILES },
    { label: "Food and Agriculture", value: CARGO_CATEGORIES.FOOD_AND_AGRICULTURE },
    { label: "Dangerous Goods", value: CARGO_CATEGORIES.DANGEROUS_GOODS },
    { label: "Electronics", value: CARGO_CATEGORIES.ELECTRONICS },
    { label: "Pharmaceuticals", value: CARGO_CATEGORIES.PHARMACEUTICALS },
    { label: "Livestock", value: CARGO_CATEGORIES.LIVESTOCK },
  ];

  const containerOptions = truckOptions.filter((el) => el.container);

  const options = data?.cargo_type === "CONTAINER" ? containerOptions : truckOptions;

  // useEffect(() => {
  //   setData({ ...data, item_size: "" });
  // }, [data.cargo_type]);

  const handleSelectChange = (value) => {
    setData({ ...data, cargo_category: value });
  };
  const handleSizeChange = (value) => {
    setData({ ...data, item_size: value });
  };

  const show = () => {
    if (notificationState.showing) return;

    setNotificationState({ show: true, showing: true });
    setTimeout(() => {
      setNotificationState({ show: false, showing: false });
    }, 2000);
  };

  const MAXIMUM_TONNAGE_VALUE = MAX_TONNAGE(data?.cargo_type);

  const handleContinue = () => {
    if (
      !data.cargo_type ||
      !data.tonnage ||
      +data.tonnage > MAXIMUM_TONNAGE_VALUE ||
      !data.cargo_value ||
      !data.item_size ||
      (data.cargo_type === "LOOSE_GOODS" && (!data.cargo_description || !data.cargo_category)) ||
      (data.cargo_type === "CONTAINER" && !data.is_container_owner) ||
      !data.offloading_duration
    ) {
      show();
      return;
    }
    setCurrentStep(1);
  };

  return (
    <StyledForm layout={"vertical"}>
      <ReactSnackBar Icon={<AiOutlineInfoCircle />} Show={notificationState.show}>
        Fill and select all fields to continue!
      </ReactSnackBar>
      <h2 className={"sub-title"} style={{ fontSize: "20px" }}>
        Cargo Type
      </h2>
      <FieldContainer onChange={handleChange}>
        <div className={"card-container"} onClick={() => containerRef.current.input.click()}>
          <PrimaryCard image={"/icon-3.png"} title={"Container"} info={"Includes contained goods in a container"}>
            <Radio checked={data?.cargo_type === "CONTAINER"} ref={containerRef} value="CONTAINER" name="cargo_type" />
          </PrimaryCard>
        </div>
        <div className={"card-container"} onClick={() => looseGoodsRef.current.input.click()}>
          <PrimaryCard
            image={"/icon-2.png"}
            title={"Loose Goods"}
            info={"Include uncontained goods like, bags, berets, cretes etc"}
          >
            <Radio
              checked={data?.cargo_type === "LOOSE_GOODS"}
              ref={looseGoodsRef}
              value="LOOSE_GOODS"
              name="cargo_type"
            />
          </PrimaryCard>
        </div>
      </FieldContainer>

      <Form.Item label="Truck Type" className="sub-title">
        <div className="selectContainer2">
          <CustomSelect
            options={options}
            size="large"
            handleChange={handleSizeChange}
            value={data.item_size}
            bordered={false}
          />
        </div>
      </Form.Item>

      {data?.cargo_type === "LOOSE_GOODS" && (
        <>
          <Form.Item label="Cargo Category" className="sub-title">
            <div className="selectContainer2">
              <CustomSelect
                options={categoryOptions}
                size="large"
                handleChange={handleSelectChange}
                value={data.cargo_category}
                bordered={false}
              />
            </div>
          </Form.Item>

          <Form.Item label="Cargo Description" name="cargo_description" required={false} className="input-item">
            <div>
              <Input.TextArea
                id="textarea"
                name="cargo_description"
                value={data.cargo_description}
                onChange={handleChange}
                rows={4}
                placeholder={"Brief description of the goods"}
                className={"textarea"}
              />
            </div>
          </Form.Item>
        </>
      )}

      <Form.Item label="Cargo Value (₦)" name="cargo_value" required={false} className="input-item">
        <div>
          <InputNumber
            name="cargo_value"
            value={data?.cargo_value}
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
            onChange={(value) => setData({ ...data, cargo_value: value })}
            placeholder="Value of goods"
            controls={false}
            style={{ width: "100%" }}
          />
        </div>
      </Form.Item>

      {data?.cargo_type === "CONTAINER" && (
        <>
          <h2 className={"sub-title"}>Container Ownership</h2>
          <FieldContainer onChange={handleChange}>
            <div className={"card-container"}>
              <SecondaryCard title={"Rented"} info={"Choose if you rented the cargo."}>
                <Radio
                  className="radio"
                  checked={data?.is_container_owner === "no"}
                  value="no"
                  name="is_container_owner"
                />
              </SecondaryCard>
            </div>
            <div className={"card-container"}>
              <SecondaryCard title={"Owner"} info={"Choose if you own the cargo"}>
                <Radio
                  className="radio"
                  checked={data?.is_container_owner === "yes"}
                  value="yes"
                  name="is_container_owner"
                />
              </SecondaryCard>
            </div>
          </FieldContainer>
        </>
      )}

      <Form.Item label="Tonnage" name="tonnage" required={false} className="input-item">
        <div>
          <Input
            name="tonnage"
            type="number"
            max={MAXIMUM_TONNAGE_VALUE}
            min={0}
            value={data.tonnage}
            onChange={handleChange}
            size={"large"}
            placeholder={`Enter the tonnage (max ${MAXIMUM_TONNAGE_VALUE}Tonnes)`}
          />
        </div>
      </Form.Item>
      <Form.Item
        type={"number"}
        label="Offloading Duration"
        name="offloading_duration"
        required={false}
        className="input-item"
      >
        <div>
          <Input
            type={"number"}
            min={0}
            name="offloading_duration"
            value={data.offloading_duration}
            onChange={handleChange}
            size={"large"}
            placeholder="Days required to offload"
          />
        </div>
      </Form.Item>

      <div className={"btn-container"}>
        <Button type={"primary"} onClick={handleContinue}>
          Continue
        </Button>
      </div>
    </StyledForm>
  );
};

const StyledForm = styled(Form)`
  input {
    border-radius: 5px;
    height: 50px;
  }

  .btn-container {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    margin-top: 40px;
  }

  button {
    width: 150px;
    height: 50px;
    border-radius: 5px;
  }

  .sub-title {
    color: #344054;
    font-weight: bold;
    font-size: 18px;
  }

  label {
    font-weight: bold;
    font-size: 18px;
    color: ${({ theme }) => theme?.colors?.secondary};
    color: #344054;
  }
`;

const FieldContainer = styled.div`
  display: flex;
  margin-bottom: 25px;
  justify-content: space-between;

  .radio {
    width: 20px;
  }

  .card-container {
    width: 45%;
    //margin-right: 30px;
  }

  @media (max-width: 900px) {
    justify-content: space-between;

    .card-container {
      width: 48%;
      margin-right: 0;
    }
  }

  @media (max-width: 800px) {
    display: block;

    .card-container {
      width: 100%;
      margin-bottom: 20px;
    }
  }
`;

export default ContainerDetailsForm;
