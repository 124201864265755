import axiosMiddleware from "redux-axios-middleware";
import axios from "axios/index";
import { applyMiddleware, createStore, compose } from "redux";
import rootReducer from "./ReducerCombine";
import { namedReducerEnhancer } from "redux-named-reducers";
import { envConfig } from "../config";
const ACCESS_TOKEN = "access_token";

export const axios_client = axios.create({
  baseURL: envConfig.REACT_APP_BASEURL,
  responseType: "json",
  timeout: 300000, // Let's say you want to wait at least 180 seconds
});

axios_client.interceptors.request.use(
  (config) => {
    config.headers.common["Authorization"] = localStorage.getItem(ACCESS_TOKEN);
    return config;
  },

  (error) => Promise.reject(error)
);

axios_client.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      if (error.response.data.status === 4011) {
        // console.log(axios.defaults.headers);
        (async () => {
          axios.defaults.headers.common["Authorization"] = null;
          localStorage.removeItem(ACCESS_TOKEN);
        })();
      }
    }
    return Promise.reject(error);
  }
);

const middlewareConfig = {
  returnRejectedPromiseOnError: true,
};

const store = createStore(
  rootReducer,
  compose(namedReducerEnhancer(rootReducer), applyMiddleware(axiosMiddleware(axios_client, middlewareConfig)))
);

export default store;
