import "./App.css";
import { Switch, Route, Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import LoginScreen from "./screens/auth-screens/LoginScreen";
import RegisterCompanyScreen from "./screens/auth-screens/RegisterCompanyScreen";
import MainScreen from "./screens/MainScreen";
import RegisterDriverScreen from "./screens/auth-screens/RegisterDriverScreen";
import ProtectedRoute from "./components/common/ProtectedRoute";
import Logout from "./components/Logout";
import "react-toastify/dist/ReactToastify.css";
import ResetPassword from "./screens/ResetPassword";
import React from "react";
import AcceptInvitation from "./screens/AcceptInvitation";
import EmailVerification from "./screens/EmailVerification";
import "react-datepicker/dist/react-datepicker.css";
import TermsOfUse from "./screens/TermsOfUse";
import PrivacyPolicy from "./screens/PrivaryPolicy";

function App() {
  return (
    <>
      <ToastContainer />
      <Switch>
        <Route path="/verify" component={EmailVerification} />
        <Route path="/pwreset" component={ResetPassword} />
        <Route path="/login" component={LoginScreen} />
        <Route path="/logout" component={Logout} />
        <Route path="/register_company" component={RegisterCompanyScreen} />
        <Route path="/register_driver" component={RegisterDriverScreen} />
        <Route path="/accept_company" component={AcceptInvitation} />
        <Route path="/terms" component={TermsOfUse} />
        <Route path="/policy" component={PrivacyPolicy} />
        <ProtectedRoute path="/app" component={MainScreen} />
        <Redirect from="/" to="/login" />
      </Switch>
    </>
  );
}

export default App;
