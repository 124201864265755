import React, { useEffect } from "react";
import { Image } from "antd";

const BillingCard = ({ detail, setDefaultBank, onDelete, handleRadio }) => {
  useEffect(() => {
    if (detail?.active) {
      setDefaultBank(detail?.account_number);
    }
  }, [detail]);

  const styles = {
    container: {
      background: detail?.active ? "#FFF2E9" : "white",
    },
    innerContainer: { display: "flex", gap: "10px" },
  };

  return (
    <div className="billing-card" style={styles.container}>
      <div style={styles.innerContainer}>
        <figure style={{ maxWidth: "35px" }}>
          <Image src={detail?.image} />
        </figure>
        <div>
          <p style={{ color: "#344054", margin: 0 }}>
            {detail?.account_number} - {detail?.account_name}
          </p>
          <p style={{ color: "#667085", margin: 0 }}>{detail?.bank_name}</p>
          {detail?.active && <p style={{ color: "#58B689", marginTop: 2 }}>Default Account</p>}
          {!detail?.active && (
            <div style={{ display: "flex", gap: "24px" }}>
              <p
                style={{ color: "#E27626", marginTop: 2, cursor: "pointer" }}
                onClick={() => {
                  setDefaultBank(detail?.account_number);
                  handleRadio(detail);
                }}
              >
                Set as default
              </p>
              <p
                style={{ color: "#667085", marginTop: 2, cursor: "pointer" }}
                onClick={(e) => {
                  onDelete(detail?._id, e);
                }}
              >
                Delete
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BillingCard;
