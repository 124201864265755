import React, { useState, useEffect } from "react";
import DashboardCard from "./DashboardCard";
import { connect } from "react-redux";
import { reduxFunctions } from "../../helper/ReduxHelper";
import moment from "moment";
import { revenuePerDay } from "../../helper/DataHelper";
import currency from "currency.js";

const DailyDistance = (props) => {
  const [data, setData] = useState([{ y: 0, x: moment().valueOf() }]);

  const getPaymentHistory = async () => {
    await props
      .getPaymentHistory()
      .then((res) => {
        setData(revenuePerDay(res.payload.data.payments));
      })
      .catch((error) => {
        // toast.error('Error getting payment history data!');
        return error;
      });
  };

  useEffect(() => {
    getPaymentHistory();
  }, []);

  return (
    <DashboardCard
      number={data[data.length - 1] ? currency(data[data.length - 1].y).format({ symbol: "₦" }) : "₦0"}
      info="Total daily revenue"
      data={data}
      head="Total daily revenue"
      unit="₦"
      revenue={true}
      title="revenue"
      loading={props.loadingPayment}
    />
  );
};

const mapStateToProps = ({ user, paymentHistory, loadingPayment }) => ({ user, paymentHistory, loadingPayment });

const mapDispatchToProps = reduxFunctions;
export default connect(mapStateToProps, mapDispatchToProps)(DailyDistance);
