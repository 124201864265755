import React from "react";
import { getUser } from "../../helper/JwtHelper";
import RegisterDriverForm from "../../components/RegisterDriverForm";
import { Redirect } from "react-router-dom";
import AuthLayout from "../../components/layout-components/AuthLayout";

const RegisterDriverScreen = (props) => {
  const handleRedirect = () => {
    props.history.push("/verify");
  };

  if (getUser()) return <Redirect to="/app" />;

  return (
    <AuthLayout>
      <RegisterDriverForm handleRedirect={handleRedirect} />
    </AuthLayout>
  );
};

export default RegisterDriverScreen;
